import React from 'react'
import { useState, useEffect} from 'react';
import Select from 'react-select';
import axios from 'axios';
import { Box } from '@mui/material';
import "../../CSS/index.css";
import CustomStyles from '../../customComponent/DDownStyles';
import { useDispatch, useSelector } from 'react-redux';

import { ModelSelectedPerKwData, getPerkwData } from '../../Redux/ApiCall/SelectedPerkw';

const USDperKw = () => {


  useEffect(()=>{
    dispatch((getPerkwData())) 
  },[]);

    const{
      PerKWdata,
    selectedPerKW,
    } = useSelector((store)=> store.selectedPerkw);
const dispatch = useDispatch()


  return (
  
    <Box   width={'60%'} sx={{marginLeft:'25%'}}>
         <Select 
                      styles={CustomStyles}
                      options={PerKWdata}
                      value={selectedPerKW}
                      onChange={newValue => dispatch(ModelSelectedPerKwData(newValue))}
                      // onInputChange={value=>dispatch({type:'field', field:"InputValue3" , value: value})}
                      getOptionLabel={(currency) => currency.Column1}
                      placeholder={'Find Currency'} />
                      <h1 className='headingStyle'>GlobalPower price per KW</h1>
                      <h6>Country: {selectedPerKW.Column1}</h6>
                      <h6>$perKW : {selectedPerKW.Column2}</h6>
                     
    </Box>
  
  )
}





export default USDperKw