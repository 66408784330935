
import React, { useContext } from "react";
import "../../CSS/index.css";
import HydroBox1 from "./HydroCompt/HydroBox1/HydroBox1";
import HydroBox2 from "./HydroCompt/HydroBox2/HydoBox2";
import HydroBox3 from "./HydroCompt/HydroBox3/HydroBox3";
import HydroBox4 from "./HydroCompt/HydroBox4/HydroBox4";
import {AppBar as BottomBar, Grid, Box, Toolbar } from "@mui/material";
import CustomHorizontalSlider from "../../customComponent/CstmHozSlider";
import CustomCheckBox from "../../customComponent/CustomCheckBox";
import CustomSlider1 from "../../customComponent/CustomSlider1";
import "../../CSS/HydroPage.css";
import { useDispatch, useSelector, } from "react-redux";
import { HydroCapacitySlider, ModelPIBCV, HydroChillerSlider, HydroPerKWSlider, HydroPerHourSlider, SlideSlider7_1, ModelVSDPUMP  } from "../../Redux/Components/HydroPlant/HydroPlantSlice";
import TopBar from "../../AppBar/AppBar";

const HydroPage = () => {


    const { Hydrocapacity,PIBCV,VSDPumps , Hydrochiller, HydroPerKW, HydroPerHour, slider6_18, slider6_19, slider6_21, slider6_20, slider6_1, slider6, slider7_1, slider7_3, metric } = useSelector((store)=> store.hydroplant); 
    const dispatch = useDispatch()

    //Formula for Hydrolic pages
    const label44_9 = Hydrocapacity;
    const label44_16 = Hydrocapacity;
    const label44_13 = HydroPerKW / 100;
    const label44_15 = HydroPerHour;
    const label44_14 = Hydrochiller / 100;
    const label54 = slider6 / 100;
    const label60_1 = slider6 / 100;
    const label55 = slider7_3 / 100;
    const label56 = slider7_3 / 100;
    const label54_1 = slider6_1 / 100;
    const label85 = .042205;
    const label80_1 = slider7_1 / 100;





    const label48_34 = Math.round(label44_9 * 3.5);

    const label48_54 = Math.round(label48_34 * label85) + slider6_20;
    const label97 = Math.round(label48_54 * .75);
    const label102 = Math.round(label48_54 * 15.850);
    const label48_55 = ((label48_34 / 100 + 4) + slider6_21).toFixed(2);
    const label53_1 = Math.floor(label48_55 * .102);

    const label48_57 = Math.round(label48_34 * .054) + slider6_18;
    const label98 = Math.round(label48_57 * .8);
    const label105 = Math.round(label98 * 15.850);

    const label48_58 = parseFloat(label48_55 * .8 + slider6_19).toFixed(2);
    const label63_1 = Math.round(label48_58 * .102);
    const label48_41 = label48_57 * 90 / 100;
    const label48_49 = Math.pow((label48_41 / label48_57), 2) * label48_58;
    const label48_53 = (label48_41 * 1000 * 9.81 * label48_49) / (Math.pow(10, 6) * (80 / 100) * (69 / 100));
    const label48_38 = (label48_54 * 1000 * 9.81 * label48_55) / (Math.pow(10, 6) * (90 / 100) * (79.5) / 100);
    const label48_45 = label48_38 * (label44_15 * 0.1);
    const label48_36 = label48_54 * 80 / 100;
    const label48_44 = Math.pow((label48_36 / label48_54), 2) * label48_55;
    const label48_47 = (label48_36 * 1000 * 9.81 * label48_44) / (Math.pow(10, 6) * (68 / 100) * (59 / 100));
    const label48_50 = label48_47 * (label44_15 * 0.6);
    const label48_35 = label48_54*90/100;
    const label48_40 = Math.pow((label48_36/label48_35),2)* label48_55;
    const label48_42 = (label48_35 * 1000 * 9.81 * label48_40)/(Math.pow(10,6)*(80/100)*(69/100))

    const label48_48 = label48_42 * (label44_15 * 0.3);

    const label48_30 = label48_45 + label48_50 + label48_48;
    const label48_51 = (label48_57 * 1000 * 9.81 * label48_58) / (Math.pow(10, 6) * (90 / 100) * (80 / 100));
    const label48_37 = label48_51 * (label44_15 * .2);
    const label48_39 = label48_53 * (label44_15 * .4);
    const label48_46 = label48_57 * 80 / 100;
    const label48_52 = Math.pow((label48_46 / label48_57), 2) * label48_58;
    const label48_56 = (label48_46 * 1000 * 9.81 * label48_52) / (Math.pow(10, 6) * (68 / 100) * (59 / 100));
    const label48_43 = label48_56 * (label44_15 * .4);
    const label48_59 = label48_37 + label48_39 + label48_43;


    const label48_29 = Math.round(label48_45 + label48_50 + label48_48);



//ChWp Opex per year // big problem
    const label49_5 = Math.round(label48_29 * label44_13);


    //Fan Opex per year
    const label50_1 = Math.round(label49_5 * 0.32);

    const label84 = slider7_1/100;
    const label55_2 = Math.round(label50_1 / label44_13) * label84;
    const label61_2 = parseFloat(12 / (label44_14 * 3.412)).toFixed(2);
    const label59_7 = label48_34 / label61_2 * label44_15;
    const label88 = label48_30 + label48_59 + label55_2 + label59_7;
    const label59_9 = label48_34 * label44_15;
    const label89 = (label59_9 / label88).toFixed(2);
    const label48_32 = Math.round(label48_37 + label48_39 + label48_43);
    const label49_7 = Math.round(label48_32 * label44_13);
    const label44_8 = Math.round(label44_16 * label44_14 * label44_15*label44_13 * label55);


    // Total Convo Setup
    const label86 = label49_5 + label49_7 + label44_8 + label50_1;
    const label92 = VSDPumps === true ? label48_30 : label48_30;


    //ChWP Opex per year
    const label49_9 = Math.round(label92 * label44_13 * label54_1);
    const label96 = label48_59;
    const label44_17 = Math.round(label44_16 * label44_14 * label44_15 * label44_13 * label80_1 * label55 * label56);
    const label50_2 = Math.round(label49_9 * .32);
    const label49_10 = Math.round(label96 * label44_13 * label54);


    //Danfoss PIBC Setup
    const label87 = label44_17 + label49_9 + label50_2 + label49_10;

    const label60 = slider6_1 / 100;
    const label106 = parseFloat(label48_29 / label44_15 / label44_9 * label60).toFixed(2);
    const label108 = parseFloat(label48_32 / label44_15 / label44_9 * label60_1).toFixed(3);
    const label55_1 = Math.round(label50_1/label44_13);
    const label59_8 = label59_7 + label48_29 + label48_32 + label55_1;
    const label60_3 = parseFloat(label59_9/label59_8).toFixed(2);
    const label62_3 = parseFloat(12/(label89 * 3.412)).toFixed(2);

    const label82 = label44_14 * label80_1
    
    
    //plant cop formula 
    const label50 = label44_9 * label82
    const label61 = label44_9 * label108
    const label51 = label44_9 * label106
   
    const label76 = (label50 + label51 + label61)/ label44_9
    const label100 = parseFloat(label76).toFixed(2)
    const label99 = parseFloat(12/ (label76) / 3.412).toFixed(2)
    

    const marks = [

        {
            value: 80,
            label: "50%"
        },
        {
            value: 90,
            label: "75%"
        },
        {
            value: 100,
            label: "100%"
        }
    ];


    return (
        <>
            <TopBar headline={
                <h1 className="headingStyle">Hyrodonic Plant Performance </h1> }/>


            <Box className="Hydro-Page"  sx={{paddingBottom:{xs:'90px',sm:'80px', md:'0px', lg:'0'}}} >
            <Box padding={'2% 2% 1% 4%'}  >
            <Box  sx={{display:{xs:'block', sm:'block', md:'none', lg:'none'}}}>
                    <CustomHorizontalSlider
                        SliderName={'Capacity Adj'}
                        display={true}
                        width={'90%'}
                        value={Hydrocapacity}
                        min={100}
                        max={1000}
                        onChange={(e) => dispatch(HydroCapacitySlider(e.target.value))}
                        BottomName={"Kwr"}
                        BottomValue={Math.round(Hydrocapacity * 3.5)}
                        unitname={'Ton'}
                        unitvalue={Hydrocapacity}
                    />
                    <CustomHorizontalSlider
                        SliderName={'Chiller COP'}
                        display={true}
                        width={'90%'}
                        value={Hydrochiller}
                        min={25}
                        max={99}
                        onChange={e => dispatch(HydroChillerSlider(e.target.value))}
                        BottomName={"Kw/kw"}
                        BottomValue={label61_2}
                        unitvalue={Hydrochiller / 100}
                    />
                    <CustomHorizontalSlider
                        display={true}
                        SliderName={'$ per KW'}
                        width={'90%'}
                        value={HydroPerKW}
                        min={1}
                        max={30}
                        onChange={e => dispatch(HydroPerKWSlider(e.target.value))}
                        unitvalue={HydroPerKW / 100}
                    />
                    <CustomHorizontalSlider
                        display={true}
                        SliderName={'Run Hours Per Year'}
                        width={'90%'}
                        value={HydroPerHour}
                        min={0}
                        max={8760}
                        onChange={e => dispatch(HydroPerHourSlider(e.target.value))}
                        unitvalue={HydroPerHour}
                    />

</Box>
                    <Grid container>
                        <Grid item md={4} xs={12} sm={12} lg={4}>
                            <HydroBox1 
                            label61_2 ={label61_2}
                            />
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} lg={6}>
                            <HydroBox2
                                label44_8={label44_8}
                                label49_5={label49_5}
                                label49_7={label49_7}
                                label50_1={label50_1}
                                label86={label86}
                                label87={label87}
                                label44_17={label44_17}
                                label49_9={label49_9}
                                label49_10={label49_10}
                                label50_2={label50_2}


                            />
                            
                        </Grid>
                        <Grid item md={2} xs={2} lg={2} sm={2}>
                            <Box sx={{display:{xs:'none',sm:'none', md:'block',lg:'block'}}} textAlign='center'>
                            <CustomSlider1
                            orientation={'vertical'}
                            display={PIBCV}
                            m={'3% 40%'}
                                SliderName={'Chiller Load%'}
                                color={'primary'}
                                min={80} max={100} value={slider7_1} height={196}
                                width={50}
                                marks={marks}
                                onChange={e => dispatch(SlideSlider7_1(e.target.value))
                                } />
                                </Box>
                        </Grid>
                        <Grid item md={12} xs={12} lg={12} >
                            <HydroBox3
                                label108={label108}
                                label106={label106}
                            />
                        </Grid>
                        <Grid item md={12} xs={12} lg={12} >
                            <HydroBox4 label105_1={label102} label53_1={label53_1} label48_55={label48_55} label48_54={label48_54}
                                label105={label105} label63_1={label63_1} label48_58={label48_58} label48_57={label48_57} label89={label89}
                                label60_3={label60_3} label62_3={label62_3} label99 ={label99} label100 = {label100}

                            />
                        </Grid>

                    </Grid>
                    <BottomBar postion='fixed' color="tertiary" sx={{top:'auto',bottom:'0', display:{xs:'block', sm:'block', md:'none', lg:'none'}}}>
              <Toolbar>
              
                     <CustomCheckBox name={"PIBCV"}
                     checked={PIBCV}
                    onChange= {event => dispatch(ModelPIBCV(!PIBCV))} />
                      <CustomCheckBox name={`VSD Pumps`} 
                       checked={VSDPumps}
                        onChange= {event => dispatch(ModelVSDPUMP(!VSDPumps))}
                    />
                    <Box mr={'10px'} >
                    <h1 className="headingStyle">Plant {<br/>} COP</h1>
                    </Box>
             <Box>
                <h1 className="headingStyle">{label99}</h1>
                <h1 className="headingStyle">{label100}</h1>
             </Box>
              </Toolbar>
              </BottomBar>
</Box>
               
            </Box>

        </>
    );
}

export default HydroPage;