
import { createTheme, responsiveFontSizes } from "@mui/material";


export const theme = createTheme({
    palette:{
        primary:{
            main: '#FFFFFF',
        },
        secondary:{
            main: 	'#000000',
        },
        tertiary:{
            main: '#B0000F'
        }
    },
    typography: {
        fontFamily: [
          '-apple-system',
          'Roboto',
          'Nunito-sans',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),

      },
});



