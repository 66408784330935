
import React, { useContext } from "react";
import { Context } from "../../../../App";
import "../../../../CSS/index.css";
import {Typography, Grid, Box} from "@mui/material";
import CustomCheckBox from "../../../../customComponent/CustomCheckBox";
import './HydroBox3.css';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CustomHorizontalSlider from "../../../../customComponent/CstmHozSlider";
import { Slider, Stack } from "@mui/material";
import LgHydroBox3 from "./LgHydroBox3";
import { useSelector, useDispatch } from "react-redux";
import { SlideSlider6, SlideSlider6_1, SlideSlider7_3,ModelGMS} from "../../../../Redux/Components/HydroPlant/HydroPlantSlice";


const HydroBox3 = (props) => {
    const { slider6_1, slider6, slider7_3, GMSingapore,VSDPumps, PIBCV} = useSelector((store)=> store.hydroplant);
    const dispatch = useDispatch()
    // const KWRT/label106 = (label48_29/label44_15/label44_9*label60).toFixed(3);
    const label62 = slider7_3 - 107;
    const label63 = label62 * -1;
    const label59 = label62 * -1 + 5;



    return (
        <>
        <Box>
           <Box sx={{display:{xs:'block', sm:'block', md:'none',lg:'none'}}}>
            
                <div width='100%' height='100%' position={'relative'} >

                <Box display={GMSingapore === true ? 'block' : 'none'}>
               <img src="../images/GMS.png" alt="GMSingapore" className="GMS"/>
             </Box>

             <Box className="PIBV" display={PIBCV === true ? 'block' : 'none'}>
                   <img src="../images/PIBV.png" alt="PIBCV" className="PIBCVHydro"/>
                   </Box>
                    <img src="../images/Plant1.png" alt="Plant1" className="HydroPlant" />
                    <div className="Typo1"><Typography variant="h6" style={{ fontSize: '13px', textAlign: 'center', color: 'white' }}>  c ECHW {<br />} {label59} </Typography></div>
                    <div className="Typo2"><Typography  variant="h6" style={{ fontSize: '13px', textAlign: 'center', color: 'white' }}>  5c {<br />} LCHW</Typography></div>
                    <div className="Typo3"> <Typography  variant="h6" style={{ fontSize: '13px', textAlign: 'center', color: 'white' }}> {label63} {<br />} Delta TC </Typography></div>
                    <div className="Typo4"><Typography  variant="h6" style={{ fontSize: '13px', textAlign: 'center', color: 'white' }}> Left RPM </Typography></div>
                    <div className="Typo5"><Typography  variant="h6" style={{ fontSize: '13px', textAlign: 'center', color: 'white' }}> Right RPM </Typography></div>
                  
                   
                    <Box display={VSDPumps === true ? 'block':'none'} >
        <img className="DriveTest1" src="../images/Drivetest1.png" alt="Drive Test"/>
        </Box>
        <Box display={VSDPumps === true ? 'block':'none'} >
        <img className="DriveTest2" src="../images/Drivetest1.png" alt="Drive Test"/>
        </Box>
                    <ArrowRightAltIcon style={{ fontSize: 50 }} color='primary' className="left-arrow" />
                    <Box className="Bypassvalve" width={'30%'}>
                        <h6>BypassValve</h6>
                        <Slider value={slider7_3}
                        size='small'
                            step={1} min={100} max={103} onChange={
                                e => dispatch(SlideSlider7_3(e.target.value))} orientation={'horizontal'} />
                        <Stack direction={'row'} justifyContent='space-between'>
                            <h6>Open</h6>
                            <h6>Close</h6>
                        </Stack>
                    </Box>
                    <CustomCheckBox className='GMSingapore' name={"Green Mark Singapore"} checked={GMSingapore} onChange={event => dispatch(ModelGMS(!GMSingapore))} />
                </div>
               
                <Box mt={'55%'}>
                <Grid container spacing={1} >
                    <Grid item xs={6} sm={6}>
                    <Box  display={VSDPumps === true ? 'block' :'none'}>
                    <h6> Left RPM {<br/>} KWRT {props.label106}</h6>
                    <CustomHorizontalSlider width={'95%'} size={'small'} min={16} max={100} value={slider6_1} onChange={e=>dispatch(SlideSlider6_1(e.target.value))} BottomValue={'Min RPM'} unitvalue={'Max RPM'}  display={true}/>
                        </Box>
                     </Grid>
                     <Grid item xs={6} sm={6}>
                     <Box  display={VSDPumps === true ? 'block' :'none'}>
                    <h6 color={'primary'}>Right RPM {<br/>} KWRT  {props.label108}</h6>
                    <CustomHorizontalSlider m={'0 0 0 10%'} width={'95%'} size={'small'} min={16} max={100} value={slider6} onChange={e=>dispatch(SlideSlider6(e.target.value))} BottomValue={'Min RPM'} unitvalue={'Max RPM'}  display={true}/>
                        </Box>
                     </Grid>
                </Grid>
                </Box>
                </Box>
            <LgHydroBox3
                label108={props.label108}
              label106={props.label106}
            />
            </Box>

        </>
    )
}
export default HydroBox3;