import React from "react";
import { Grid,Box } from "@mui/material";
import "../../../../CSS/index.css";
import { fontSize } from "@mui/joy/styles/styleFunctionSx";



const Box4SecondContainer = (props) => {
    return (
        <>
            <Grid container style={{ textAlign: 'center' }}>
                <Grid item md={12} sm={12} xs={12}>
                    <h1 className="headingStyle" style={{fontSize:'30px'}}>ROI</h1>
                </Grid>
                <Grid item md={12} sm={12} xs={12} style={{ marginTop: 12, marginBottom: 12 }}>
                <h1 className="headingStyle"  style={{fontSize:'30px'}}>{props.label35 === "NaN" ? 0 : props.label35}</h1>
                </Grid>
                <Grid item md={12} sm={12} xs={12} style={{ marginTop: 12, marginBottom: 12 }}>
                    <Box>
                        <img src="../images/X-RayTurboTT.png" alt="Turbo" width={'125%'} />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}
export default Box4SecondContainer;