
import React from "react";
import "../../../CSS/index.css";
import CustomHorizontalSlider from "../../../customComponent/CstmHozSlider";
import CustomCheckBox from "../../../customComponent/CustomCheckBox";
import { Box, Grid,Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {  ModelLowLiftMetric} from "../../../Redux/Components/LowLift/LowLiftSlice";

const ToolBox4 = (props) => {

    const {
        LowLiftMetric,
        LowLiftApp
    } = useSelector((state)=>state.LowLift);

    const dispatch = useDispatch()

    const marks = [
        {
            value: 5.0,
        },
        {
            value: 14.5,
        },
        {
            value: 25,
        }
    ];

    return (
        <Box >

            <Box margin={'3%'} mt={'50%'} sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }}>
                <CustomCheckBox checked={LowLiftMetric} name={'Metric'}
                    onChange={
                        e => dispatch(ModelLowLiftMetric(!LowLiftMetric))
                    }
                />
                <Box display={LowLiftApp === true ? 'block' : 'none'}>
                    <h1 className="headingStyle" style={{textAlign:'center'}}> Coefficient of Performance</h1>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <h1 className="headingStyle">0.7</h1>
                    <h1 className="headingStyle">0.35</h1>
                    <h1 className="headingStyle">0.17 Kw/Tn</h1>

                </Stack>
                <Box width={'90%'}>
                </Box>



                <CustomHorizontalSlider marks={marks} display={true} size={'small'} width={'90%'} min={5} max={25} value={props.label115} />
                <Stack direction={'row'} justifyContent={'space-between'} position={'relative'} marginTop={'-3%'}>
                <h1 className="headingStyle">5.0</h1>
                    <h1 className="headingStyle">10.0</h1>
                    <h1 className="headingStyle">20.0 Kw/Kw</h1>

                </Stack>

</Box>
            </Box>
            <Box mt='5%' sx={{display:{xs:'none', sm:'none', md:'block',lg:'block'}}}>
                <Grid container>
                    <Grid item md={3} lg={3}>
                        <CustomCheckBox checked={LowLiftMetric} name={'Metric'} 
                        style={{marginTop:'25%', marginLeft:'30%'}}
                            onChange={
                                e => dispatch((ModelLowLiftMetric(!LowLiftMetric)))
                            }
                        />
                    </Grid>
                    <Grid item md={6} lg={6}>

                        <Box display={LowLiftApp === true ? 'block' : 'none'} width={'100%'}>
                        <h1 className="headingStyle" style={{textAlign:'center'}}> Coefficient of Performance</h1>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                            <h1 className="headingStyle">0.7</h1>
                    <h1 className="headingStyle">0.35</h1>
                    <h1 className="headingStyle">0.17 Kw/Tn</h1>
                            </Stack>


                            <CustomHorizontalSlider marks={marks} display={true} size={'small'} width={'90%'} min={5} max={25} value={props.label115} />
                            <Stack direction={'row'} justifyContent={'space-between'} position={'relative'} marginTop={'-3%'}>
                            <h1 className="headingStyle">5.0</h1>
                    <h1 className="headingStyle">10.0</h1>
                    <h1 className="headingStyle">20.0 Kw/Kw</h1>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>)
}
export default ToolBox4;