import React from "react";
import { Grid, Box } from "@material-ui/core";
import CustomSlider1 from "../../../customComponent/CustomSlider1";
import "../../../CSS/index.css";
import CustomCheckBox from "../../../customComponent/CustomCheckBox";
import LgScreenBox2 from "./LgScreenBox2";
import { useDispatch, useSelector } from "react-redux";

import { SlideSlider2 , ModelDegradation, SliderHours, SliderPerkw} from "../../../Redux/Components/Chiller/ChillerSlice";

const Box2 = (props) => {

    const {
        perKW,
        hours,
        slider2,
        degradation
    } = useSelector((store)=> store.chiller);
    const { selectedValue } = useSelector((store) => store.selectedValue1)


    const dispatch = useDispatch()


    return (
        <>

            <Box width='100%' height='100%' sx={{display:{xs:"block",sm:"block", md:"none",ld:"none"}}}>
                <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <Grid container>
                        <Grid item xs={4} sm={4} >
                            <Box sx={{ width: '100%' }} display={degradation === true ? 'block' : 'none'}>
                                <h6>New Opex With Oil Degradation</h6>
                                <h6>{props.label38 ? props.label38 : 0}</h6>
                               
                            </Box>
                        </Grid>
                        <Grid item xs={3} sm={3} >
                            <h6>Opex Per Annum $</h6>
                            <h6>{props.label30 ? props.label30 : 0}</h6>
                        </Grid>
                        <Grid item xs={4} sm={4} >
                            <h6 >Total Cost of OwnerShip 10 years$</h6>
                            <h6>{props.label30_1 ? props.label30_1 : 0}</h6>
                        </Grid>
                        <Grid item xs={7} sm={7}>
                            <CustomSlider1
                            m={' 0 0 0  20px'}
                                width={'80%'}
                                orientation={'horizontal'}
                                display={degradation}
                                min={0} max={20}
                                value={slider2}
                                onChange={e => dispatch(SlideSlider2(e.target.value))}
                            />

                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Box display={selectedValue.Column3 === "Centrifugal Oil Free" ? "none" : 'block'}>      
                            <CustomCheckBox name={'Add Oil Degradation'}
                            checked={degradation}
                                onChange={event => dispatch(ModelDegradation(!degradation))} />
                                </Box>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <Box sx={{ width: '100%' }} display={degradation === true ? 'block' : 'none'}>
                                <h5>{props.label26}</h5>
                                <h5>Oil Degradation%</h5>
                            </Box>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Box sx={{ width: '100%' }} display={degradation === true ? 'block' : 'none'}>
                                <h5>{props.label32 ? props.label32 : 0}</h5>
                                <h5>Years of Operation</h5>
                            </Box>
                        </Grid>

                    </Grid>
                </Box>
                <Box sx={{margin:"6%"}}>
                    <CustomSlider1
                        display={true}
                        width={"90%"}
                        value={hours}
                        SliderName={`Operating Hours Per Year`}
                        min={-1}
                        max={8760}
                        onChange={e => dispatch(SliderHours(e.target.value))}
                        BottomValue={hours}
                    />
                     <CustomSlider1
                                    display={true}
                                    width={'90%'}
                                    SliderName={'$ Per KW/hr'}
                                    min={0}
                                    max={35}
                                    value={perKW}
                                    onChange={e => dispatch(SliderPerkw(e.target.value))}
                                    BottomValue={props.label25 === 'NaN' ? '0' : props.label25} />
                </Box>
            </Box>
            <Box sx={{display:{xs:"none", sm:"none", md:"block", lg:"block"}}}>
                <LgScreenBox2
                     label26 ={props.label26}
                    label32 ={props.label32}
                    label25 ={props.label25}
                    label30 = {props.label30}
                    label30_1 = {props.label30_1}
                    label38 = {props.label38}
                />
                </Box>
        </>
    )
}
export default Box2;