
import React, { useContext } from "react";
import "../../../CSS/index.css";
import { Grid, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const OFCBox2=(props)=>{
    
    const {
        CheckValve
    } = useSelector((store)=>store.OFC);



    return (
        <>
        
        <Box margin={'0 2%'}>
            <Grid container>
                <Grid item sm={6} lg={6} md={6} xs={6}>
                    <h6>Chiller Opex Per year </h6>
                    <Box display={CheckValve === true ? 'block' : 'none'} >
                        <h6> 
                        New Opex Per year 
                        </h6>
                    </Box>
                    <Box display={CheckValve === true ? 'block' : 'none'} >
                        <h6> 
                        Saving Opex Per year
                        </h6>
                    </Box>
               

                </Grid>
                <Grid item sm={1} lg={1} md={1} xs={1}>

                <h6>$</h6>
                    <Box display={CheckValve === true ? 'block' : 'none'} >
                        <h6> 
                        $ 
                        </h6>
                    </Box>
                    <Box display={CheckValve === true ? 'block' : 'none'} >
                        <h6> 
                        $
                        </h6>
                    </Box>

                </Grid>
                <Grid item sm={4} lg={4} md={4} xs={4}>

                <h6>{props.label71_4}</h6>
                    <Box display={CheckValve === true ? 'block' : 'none'} >
                        <h6> 
                        {props.label71_5}
                        </h6>
                    </Box>
                    <Box display={CheckValve === true ? 'block' : 'none'} >
                        <h6> 
                        {props.label48}
                        </h6>
                    </Box>
                </Grid>
            </Grid>
</Box>
        
        </>
    );
}

export default OFCBox2;