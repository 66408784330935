import React from "react";
import { Grid  } from "@mui/material";
import CustomCheckBox from "../../../../customComponent/CustomCheckBox";
import { useDispatch, useSelector } from "react-redux";
import { ModelCurrency, ModelAdj, ModelImperial, Modelmetric } from "../../../../Redux/Components/Chiller/ChillerSlice";


const Box4ThirdContainer = () => {
    
    const {
        currency,
        Adj,
        imperial,
        metric
    } = useSelector((store)=> store.chiller);

    const dispatch = useDispatch()
    return (
        <>
     
            <Grid container >
                <Grid item md={3} sm={3} xs={3} lg={3}>
                    <CustomCheckBox name={"Capp/Eff Adj"} checked={Adj} onChange= {event => dispatch(ModelAdj(!Adj))} />
                </Grid>
                <Grid item md={3} sm={3} xs={3} lg={3}>
                    <CustomCheckBox name={"Currency"} checked={currency} onChange= {event => dispatch(ModelCurrency(!currency))} />
                </Grid>
                <Grid item md={3} sm={3} xs={3} lg={3}>
                    <CustomCheckBox checked={imperial} name={"Imperial"}
                    onChange= {event => dispatch(ModelImperial(!imperial))} />
                </Grid>
                <Grid item md={3} sm={3} xs={3} lg={3}>
                    <CustomCheckBox checked={metric} name={"Metric"} 
                        onChange= {event => dispatch(Modelmetric(!metric))}
                    />
                </Grid>
            </Grid>
     
        </>
    )
}
export default Box4ThirdContainer;