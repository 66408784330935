import React from "react";
import { FormControlLabel } from "@material-ui/core";

import "../CSS/index.css";

import { Checkbox, styled } from "@mui/material";

const CheckBoxStyled = styled(Checkbox)(({theme})=>({
    color:'white',
    '&.Mui-checked':{
        color:'white'
    }
}))
const CustomCheckBox =(props)=>{
    return (
        <>

        <FormControlLabel style={props.style} value="end" className="props.className" control={<CheckBoxStyled checked={props.checked} onChange={props.onChange}/>} 
        label={<h5>{props.name}</h5>} />

        </>
    )
}

export default CustomCheckBox;