import { Dialog, Box, DialogTitle } from '@mui/material'
import React from 'react'
import { StyledButton } from '../../customComponent/StyledButton'
 

const Disclaim = (props) => {
  return (
    <Dialog open={props.notAgreed} onClose={props.handleDisc} style={{textAlign:'center'}}>
    <DialogTitle>Disclaimer Agreement</DialogTitle>
    <Box sx={{padding:'3%'}}>
        <h4
         style={{color:'Black', margin:'0%', padding:'0%'}}>Please agree to the following to continue:</h4>
        <h4 style={{color:'Black', marginTop:'2%', marginBottom:'5%'}}>
        Danfoss assumes no responsibility or liability for any errors or omissions in the content of this site. The information contained in this site is provided on an "as is" basis with no guarantees of completeness, accuracy, usefulness or timeliness.
        </h4>
        <StyledButton fullWidth style={{backgroundColor:'red'}} onClick={props.handleDisc} >
   Agreed
        </StyledButton>

    </Box>


    </Dialog>
  )
}

export default Disclaim