

const CustomStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? 'white' : 'black',
    backgroundColor: state.isFocused ? '#B0000F' : 'white',
    padding:10,
    
  }),
  control: (provided, state) => ({
    ...provided,
    marginTop: "1%",
    marginBottom:'2%',
    boxShadow: state.isFocused ? null : 'none' 
  })
  }
 export default CustomStyles;