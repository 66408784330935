import React from "react";
import { Box, Typography } from "@mui/material";
import CustomSlider from "../../../customComponent/Customslider";
import "./ToolBox3.css";
import "../../../CSS/index.css";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useDispatch, useSelector } from "react-redux";
import { SlideSlider6_12 } from "../../../Redux/Components/LowLift/LowLiftSlice";

const LgScrnToolBox3 = (props) => {

    const { slider6_12, LowLiftApp, LowLiftMetric } = useSelector((state)=>state.LowLift);
    const dispatch = useDispatch()
  return (
    <Box position={'relative'} sx={{display:{xs:'none', sm:'none', md:'block', lg:'block'}}}>   


         <img src="../images/power.png" alt="Plant1" className="HydroPlant4" />
                    <div className="EWT2"><Typography variant="h6" style={{ fontSize: '15px', textAlign: 'center', color: 'white' }}> EWT {LowLiftMetric === true ? props.label64_4 +' °F': props.label90 + " °C"} </Typography></div>
                    <div className="LWT2"><Typography variant="h6" style={{ fontSize: '15px', textAlign: 'center', color: 'white' }}> LWT {LowLiftMetric === true ? props.label68 + ' °F': props.label90_2+ " °C"} </Typography></div>
                    <div className="EWT3"><Typography variant="h6" style={{ fontSize: '15px', textAlign: 'center', color: 'white' }}> EWT {LowLiftMetric === true ? props.label64_5 + ' °F' :props.label90_1+ " °C"} </Typography></div>
                    <div className="LWT3"><Typography variant="h6" style={{ fontSize: '15px', textAlign: 'center', color: 'white' }}> LWT  {LowLiftMetric === true ? props.label64_6 + ' °F' : props.label90_3+ " °C"} </Typography></div>
                   <Box  display={props.label79 < 13 ? 'block' : 'none'} className="left-arrow2" > <ArrowRightAltIcon  style={{ fontSize: 150 }} color='primary'  /></Box>
                    
                    
                    <Box className="RequiredPopup1">
                    <Box  display={props.label79 < 13 ? 'block' : 'none'}>
                            <h6 style={{textAlign:'left'}}>
                            Low Lift Required
                            </h6>
                        </Box>


                        <Box  display={props.label79 < 13 ? 'block' : 'none'}>
                        <img  className="RequiredLowLift1" src="../images/lowlift.png" alt='Images on Lowlift'/></Box>
                        <Box display={slider6_12 > 19 ? 'block' : 'none'}>
                            <h6 style={{textAlign:'left' , marginLeft:'-10%'}}>
                            High SST Compressor {<br/>} Version Recommended
                            </h6>
                        </Box>
                        
                    </Box>


                    <Box>
                    <Box position={'absolute'} mt={'2%'} >
                    <Box display={LowLiftApp === true ? 'block' : 'none'}>
                    <CustomSlider height={149}
                  SliderName={"High SST"} value={slider6_12} min={8} max={26}
                   onChange={
                    e => dispatch(SlideSlider6_12(e.target.value))
                }
            />

            </Box>
            </Box>
   </Box>
</Box>
  )
}

export default LgScrnToolBox3