
import React, { useContext } from "react";
import CustomCheckBox from "../../../../customComponent/CustomCheckBox";
import './HydroBox3.css';
import '../../../../CSS/index.css';
import { Slider, Stack , Box} from "@mui/material";
import CustomSlider1 from "../../../../customComponent/CustomSlider1";
import { useSelector, useDispatch } from "react-redux";
import { SlideSlider6, SlideSlider6_1, SlideSlider7_3,ModelGMS} from "../../../../Redux/Components/HydroPlant/HydroPlantSlice";

const LgHydroBox3 = (props) => {

  const { slider6_1, slider6, slider7_3, GMSingapore, VSDPumps } = useSelector((store)=>store.hydroplant);

  const dispatch = useDispatch()

  const label62 = slider7_3 - 107;
  const label63 = label62 * -1;
  const label59 = label62 * -1 + 5;

  return (
    <Box position={'relative'} sx={{display:{xs:'none', sm:'none', md:'block', lg:'block'}}}>
      <img src="../images/power.png" alt="Plant1" className="HydroPlant2" />
      
      <Box display={GMSingapore === true ? 'block' : 'none'}>
               <img src="../images/GMS.png" alt="GMSingapore" className="GMS2"/>
             </Box>
             
     
      <div className="Typo6"><h6> {label59} c ECHW  </h6></div>
      <div className="Typo7"><h6>  5 c LCHW</h6></div>
      <div className="Typo8"> <h6>{label63} Delta TC </h6></div>
      <Box className="Bypassvalve2">
        <h6 style={{textAlign:'left'}}>Open</h6>
        <Stack direction={'row'} height={'4.5em'}>
          <Slider
            orientation="vertical"
            value={slider7_3}
            min={100}
            max={103}
            onChange={e => dispatch(SlideSlider7_3(e.target.value))}

          /> <h6>Bypass {<br />} valve</h6>
        </Stack>
        <h6 style={{ textAlign: 'left' }}>Close</h6>
      </Box>
      <CustomCheckBox className='GMSingapore2' checked={GMSingapore} name={"Green Mark Singapore"} onChange={event => dispatch(ModelGMS(!GMSingapore))} />
  
    
      <Box className="LeftRpm">

          <Stack direction={'row'} width={70} spacing={2} >
          <Box display={VSDPumps === true ? 'block':'none'}> <h5>KWRT {props.label106}</h5></Box>
            <CustomSlider1
            display={VSDPumps}
            orientation={'vertical'}
              size={'small'}
              height={85}
              width={34}
              value={slider6_1}
              min={16}
              max={100}
              onChange={e=>dispatch(SlideSlider6_1(e.target.value))}
            />
            <Stack direction={'column'} >
              <Box display={VSDPumps === true ? 'block':'none'}> <h6>Max RPM</h6></Box>
              <Box display={VSDPumps === true ? 'block':'none'}> <h6>Min RPM</h6></Box>
            </Stack>
            <Box display={VSDPumps === true ? 'block':'none'}>
           <img className="DriveTest3" src="../images/Drivetest1.png" alt="Drive Test"/>
           </Box>

          </Stack>
         
          
        </Box>
    
        <Box className="RightRpm">
            <Stack direction={'row'}width={70} spacing={2} >
            <Box display={VSDPumps === true ? 'block':'none'}> <h5>KWRT {props.label108}</h5></Box>
              <CustomSlider1
              display={VSDPumps}
              orientation={'vertical'}
                size={'small'}
                height={85}
                width={34}
                value={slider6}
                min={16}
                max={100}
                onChange={e=>dispatch(SlideSlider6(e.target.value))}
              />
              <Stack direction={'column'} >
              <Box display={VSDPumps === true ? 'block':'none'}> <h6>Max RPM</h6></Box>
              <Box display={VSDPumps === true ? 'block':'none'}> <h6>Min RPM</h6></Box>
              </Stack>
              <Box display={VSDPumps === true ? 'block':'none'} >
        <img className="DriveTest4" src="../images/Drivetest1.png" alt="Drive Test"/>
        </Box>
              
            </Stack>

      </Box>
      </Box>

      )
}

      export default LgHydroBox3;


