
import { Navigate, Route } from "react-router-dom"


const PrivatRoute = ({ children}) => {
   
    function isauth (){
         const authToken = localStorage.getItem("authToken", JSON.stringify("authToken"));
         return authToken;
    }
    const authed = isauth()
  return authed ? children : <Navigate to="/login" />;
}

export default PrivatRoute