import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Drawers: false,
    selectedItems3:'',
    OilDegradation:false,
OilDegradationVideo:false,
GlobalPower:false,
IPLVWater:false,
IPLVAir:false,
ECWT:false,
LCHWAir:false,
LCHWWater:false,
PlantEff:false,
IPLVWT:false,
USDKWR:false,
USDTon:false,
Performance:false,
DTC:false
}

const ValueToolSlice = createSlice({
    name:'ValueTool',
    initialState,
    reducers:{
        ModelDTC:(state, action)=>{
            state.DTC = action.payload
        },
        ModelPerformance:(state, action)=>{
            state.Performance = action.payload
        },
        ModelUSDTon:(state, action)=>{
            state.USDTon = action.payload
        },
        ModelUSDKWR:(state, action)=>{
            state.USDKWR = action.payload
        },
        ModelIPLVWT:(state, action)=>{
            state.IPLVWT = action.payload
        },
        ModelPlantEff:(state, action)=>{
            state.PlantEff = action.payload
        },
        ModelLCHWWater:(state, action)=>{
            state.LCHWWater= action.payload
        },
        ModelLCHWAir:(state, action)=>{
            state.LCHWAir = action.payload
        },
        ModelECWT:(state, action)=>{
            state.ECWT = action.payload
        },
        ModelIPLVAir:(state, action)=>{
            state.IPLVAir = action.payload
        },
        ModelIPLVWater:(state, action)=>{
            state.IPLVWater = action.payload
        },
        ModelGlobalPower:(state, action)=>{
            state.GlobalPower = action.payload
        },
        ModelOilDegradationVideo:(state, action)=>{
            state.OilDegradationVideo = action.payload
        },
        ModelOilDegradation:(state, action)=>{
            state.OilDegradation = action.payload
        },
        ModelDrawers:(state, action)=>{
            state.Drawers = action.payload
        },
        ModelSelecteditem3: (state, action)=>{
            state.selectedItems3 = action.payload
        }
    }
}
)

export const {ModelDrawers, ModelSelecteditem3, ModelDTC, ModelECWT, ModelGlobalPower, ModelIPLVAir
, ModelIPLVWT, ModelIPLVWater, ModelLCHWAir, ModelLCHWWater, ModelOilDegradation, ModelOilDegradationVideo, ModelPerformance
, ModelPlantEff, ModelUSDKWR,ModelUSDTon} = ValueToolSlice.actions
export default ValueToolSlice.reducer