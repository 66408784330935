import React from "react";
import HandymanIcon from '@mui/icons-material/Handyman';
import "../CSS/index.css";
import { styled , Button} from "@mui/material";


const StyledButton =styled(Button)(({ theme})=>({
    variant:'outlined',
    borderRadius:'5px',
    outline: 'solid white',
    outlineColor:'white',
    borderColor:'white',
    textAlign:'center',
    '&:hover': {
        backgroundColor: '#630606',
        borderColor: '#0062cc',
        boxShadow: 'none',
        outline:'5px solid white'
      },
}));

const ButtonCompt =(props)=>{
    return (
        <>
        <StyledButton variant="outlined" sx={{width:'220px'}} ><h6>
            {props.btnName}
        </h6></StyledButton>
           <Button variant="text" >{<HandymanIcon/>}</Button>
        </>
    )
}
export default ButtonCompt;


