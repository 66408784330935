
import React from "react";
import { Grid, Box } from "@mui/material";
import "../../../CSS/index.css";
import { useSelector } from "react-redux";

const ToolBox2 = (props) => {

    const {LowLiftApp} = useSelector((state)=>state.LowLift);


    return (
        <>
  <Box margin={'0 2%'}>
            <Grid container>
                <Grid item sm={6} lg={6} md={6} xs={6}>
                    <h6> Chiller Opex Per year </h6>
                    <Box display={LowLiftApp === true ? 'block' : 'none'}>
                        <h6>New Opex Per year</h6>
                    </Box>


                    <Box display={LowLiftApp === true ? 'block' : 'none'}>
                        <h6>Saving Opex Per year</h6>
                    </Box>


                </Grid>
                <Grid item sm={4} lg={4} md={4} xs={4}>
                <h6>$ {" "} {props.label71_1}</h6>
                <Box display={LowLiftApp === true ? 'block' : 'none'}>
                        <h6> $ {" "} {props.label71_2}</h6>
                    </Box>

                    <Box display={LowLiftApp === true ? 'block' : 'none'}>
                        <h6> $ {" "}{props.label74}</h6>
                    </Box>
                </Grid>
            </Grid>
</Box>
        </>
    )
}

export default ToolBox2