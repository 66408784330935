import React from "react";
import { styled, AppBar as BottomBar, Grid, Box, Toolbar } from "@mui/material";

import Box1cop from "./Box1/Box1";
import Box2 from "./Box2/Box2";
import Box4 from "./Box4/Box4";
import Box3 from "./Box3/Box3";
import Select from 'react-select';
import CustomCheckBox from "../../customComponent/CustomCheckBox";
import '../../CSS/Chiller.css';
import CustomStyles from "../../customComponent/DDownStyles";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ModelAdj, ModelImperial, Modelmetric, ModelCurrency} from "../../Redux/Components/Chiller/ChillerSlice";

import { ModelSelectedValue, ModelInput1 } from "../../Redux/ApiCall/SelectedValueSlice";
import { ModelSelectedValue2, ModelInput2 } from "../../Redux/ApiCall/SelectedValue2";
import { ModelSelectedItems } from "../../Redux/ApiCall/SelectedItem";
import AppBar from "../../AppBar/AppBar";

import { getCurrency } from "../../Redux/ApiCall/SelectedItem";


const ChillerPage = () => {

  const { selectedValue, data } = useSelector((store) => store.selectedValue1)
  const { selectedItems , currencydata} = useSelector((store) => store.selectedItems)
  const { selectedValue2} = useSelector((store) => store.selectedValue2)

  const {
    slider3, slider2, slider3_1, 
    slider1, slider5_1, slider2_3, Adj, imperial, metric, capacity, EffAdj, perKW, hours,
    currency
  } = useSelector((store) => store.chiller);


  const dispatch = useDispatch()
    


  const label36 = capacity;
  const slider1_5 = perKW;
  const label4 = (slider1_5 / 100);
  const slider1_4 = hours;
  const label14_6 = selectedValue.Column3;
  const label14_7 = selectedValue.Column1;
  const slider5 = EffAdj;
  const label45 = slider5 / 100;
  const label3 = slider1_4;
  const label14_1 = selectedValue.Column7 + label45;

  ///adsadasdas
  const label14_2 = selectedValue.Column6;
  const label15_1 = (12 / (selectedValue.Column6 * 3.412).toFixed(2));
  const label12 = Math.round(selectedValue.Column5 * .28434 + (capacity / 3.517));

  const label15 = (12 / (label14_1 * 3.412)).toFixed(2);
  const label10 = Math.round(label12 * .58 * label15 * label4 * label3);
  const label16 = Math.round(label10 / label4);
  const label16_1 = Math.round(label16 * 0.000718);
  const label47_4 = label14_6 === "Centrifugal Oil Free" ? 25 : 0;
  const label47_1 = label14_7 === "Air Cooled" ? 450 : 0;
  const label47 = label14_7 === "Water Cooled" ? 300 : 0;
  const label14 = Math.round( selectedValue.Column5 + label36);
  const label2 = label47_4 + label47_1 + label47;
  const label17 = label2 * label12 + slider3 * 300;
  const label17_1 = Math.round(label17 / label14);
  const label17_2 = Math.round(label17 / label12);
  const label30_2 = Math.round(selectedItems.Column3 * label17);
  const label20 = label10 * 10 + label17;
  //Oil degradation
  const label8 = slider2;
  const label26 = label8;
  const label32 = Math.round(label8 / 0.8);
  const label25 = (selectedItems.Column3 * label4).toFixed(2);
  const label30 = Math.round(selectedItems.Column3 * label10);
  const label30_1 = Math.round(selectedItems.Column3 * label20);
  const label40 = label8 / 100 + 1;
  const label38 = Math.round(label10 * label40);
  const label21 = label26 / 100;
  const label27 = Math.round(label10 * label21); 
  const label34 = label10 + label27;



  //Box3
  const label39 = slider1;
  const label12_1 = Math.round(selectedValue2.Column5 * 0.28434 + (label39 / 3.5));

  const label46 = slider5_1 / 100;
  const label14_4 = selectedValue2.Column7 + label46;


  const label15_2 = (12 / (label14_4 * 3.412)).toFixed(2);


  const label10_1 = Math.round(label12_1 * 0.58 * label15_2 * label4 * label3);
  const label16_2 = Math.round(label10_1 / label4);
  const label16_3 = Math.round(label16_2 * 0.000718);


  const label14_8 = selectedValue2.Column3;
  const label14_9 = selectedValue2.Column1;
  const label47_6 = label14_8 === "Centrifugal Oil Free" ? 25 : 0;

  const label47_7 = label14_9 === "Water Cooled" ? 300 : 0;
  const label47_8 = label14_9 === "Air Cooled" ? 450 : 0;
  const label7 = label47_6 + label47_7 + label47_8;
  const label17_3 = label7 * label12_1 + slider3_1 * 300;
  const label14_3 = selectedValue2.Column5 + label39;
  
  const label17_4 = Math.round(label17_3 / label14_3);
  const label17_5 = Math.round(label17_3 / label12_1);
  const label30_5 = Math.round(selectedItems.Column3 * label17_3);
  const label20_1 = Math.round(label10_1 * 10 + label17_3);
  // Box4
  const label33 = slider2_3;
  const label26_2 = label33;
  const label32_1 = Math.round(label33 / 0.80);
  const label43 = label33 / 100 + 1;
  const label38_1 = Math.round(label10_1 * label43);
  const label30_3 = Math.round(selectedItems.Column3 * label10_1);
  const label30_4 = Math.round(selectedItems.Column3 * label20_1);
  const label21_1 = label33 / 100;
  const label27_1 = Math.round(label10_1 * label21_1);
  const label34_1 = label10_1 + label27_1;
  //ROI
  const label9 = label17_3 - label17;
  const label37 = label34 - label34_1;
  const label35 = (label9 / label37).toFixed(1);




  const BoxStyled = styled(Box)(({ theme }) => ({
    width: '80%',
    // display: currency === true ? 'block' : 'none',
    [theme.breakpoints.up('sm')]: {
      width: '65%',
      marginLeft: '15%'
    },
    marginLeft: '12%',
    textAlign: 'center'
  }));




  const customFilter = (option, searchText) => {
    if (
      option.data.Column4?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.Column1?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.Column2?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.Column3?.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };



  return (
    <>
      <AppBar
        headline={
          <Box textAlign={'center'}>
            <h1 className="headingStyle">Competative Analysis Tool 6.0</h1>

            <BoxStyled display={currency === true ? "block" : "none"}>
              <Select
              onFocus={false}
                className="selectStyle"
                styles={CustomStyles}
                options={currencydata}
                value={selectedItems}
                onChange={newValue => dispatch(ModelSelectedItems(newValue))}
                getOptionLabel={(currency) => currency.Column2}
                placeholder={'Find Currency'} />
            </BoxStyled>

          </Box>
        } />


      <Box sx={{ paddingBottom: { xs: '100px', sm: '100px', md: '10px', lg: '20px' } }} minHeight={'100vh'} className='Chiller-components'>

        <Grid container >
          <Grid item xs={12} md={7} lg={6} sm={12}>
            <Box1cop
              label30_2={label30_2}
              label16={label16}
              label16_1={label16_1}
              label17_1={label17_1}
              label17_2={label17_2}
              label14={label14}
              label14_1={label14_1}
              label14_2={label14_2}
              label12={label12}
              label15_1={label15_1}
              label15={label15}
              options={data}
              selectedValue={selectedValue}
              customFilter={customFilter}
              HandleChange={newValue => dispatch(ModelSelectedValue(newValue))}
            />
          </Grid>

          <Grid item xs={12} md={5} lg={6} sm={12}>
            <Box2
              label26={label26}
              label32={label32}
              label25={label25}
              label30={label30}
              label30_1={label30_1}
              label38={label38}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={6}>

            <Box3
              label16_2={label16_2}
              label16_3={label16_3}
              label17_4={label17_4}
              label17_5={label17_5}
              label30_5={label30_5}
              options={data}
              selectedValue={selectedValue2}
              customFilter={customFilter}
              HandleChange={newValue => dispatch(ModelSelectedValue2(newValue))}
              handleInputChange={value => dispatch(ModelInput2(value))}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={6}>
            <Box4
              label26_2={label26_2}
              label32_1={label32_1}
              label38_1={label38_1}
              label30_3={label30_3}
              label30_4={label30_4}
              label35={label35}
            />
          </Grid>
        </Grid>

        {/* BottomBar Which appear on small and medium screen sizes */}

        <Box >
          <BottomBar postion='fixed' color="tertiary"
            sx={{
              top: 'auto', bottom: '0',
              display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' }
            }}>
            <Toolbar>

              <Stack direction={'row'} justifyContent='space-between'>

                <CustomCheckBox name={"Eff Adj"} checked={Adj} onChange={event => dispatch(ModelAdj(!Adj))} />
                <CustomCheckBox name={"IMP"}
                  checked={imperial}
                  onChange={event => dispatch(ModelImperial(!imperial))} />
                <CustomCheckBox name={<CurrencyExchangeIcon color="white"
                />}
                  checked={currency}
                  onChange={event => dispatch(ModelCurrency(!currency))
                  }
                />
                <CustomCheckBox name={"M"}
                  checked={metric}
                  onChange={event => dispatch(Modelmetric(!metric))}
                />
                <h1 className="headingStyle" style={{ padding: '6px 7px 0 0' }}> ROI</h1>
                <h1 className="headingStyle" style={{ padding: '6px 0 0 0' }}> {label35 === 'NaN' ? 0 : label35}</h1>
              </Stack>

            </Toolbar>
          </BottomBar>
        </Box>
      </Box>

      <Box>

      </Box>


    </>
  );

}


export default ChillerPage;

