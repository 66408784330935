import { Grid, Box, styled } from "@mui/material";
import CustomStyles from "../../../customComponent/DDownStyles";
import Select from "react-select";
import CustomSlider1 from "../../../customComponent/CustomSlider1";
import MidContent from "./Box1compt/Box1MidCompt";
import "../../../CSS/index.css";
import LgScreen from "./LgScreen";
import BoxField from "../Smallcomponents/BoxField";
import { useDispatch, useSelector } from "react-redux";
import { SliderCapacity , SlideEffAdj, Slideslider3} from "../../../Redux/Components/Chiller/ChillerSlice";
import React, { useRef } from "react";

const Box1cop = (props) => {

  const { capacity, EffAdj, Adj, slider3, degradation } = useSelector((store)=> store.chiller);
  const {selectedValue} = useSelector((store)=>store.selectedValue1)
  const dispatch = useDispatch()
  const selectRef = useRef(null);

  const Box1Styled = styled(Box)(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "290px",
      marginLeft: "29px",
    },
  }));

  return (
    <Box>
      <Box sx={{ m: "1% 0 1% 2% " }}>
        <Grid container>
          <Grid item xs={5} sm={5} md={5} lg={12}>
            <Box1Styled>
              <Select
              ref={selectRef}
                getOptionValue={(option) => `${option}`}
                isSearchable={true}
                filterOption={props.customFilter}
                className="selectStyle"
                styles={CustomStyles}
                options={props.options}
                value={selectedValue}
                onChange={props.HandleChange}
                getOptionLabel={(option) => option.Column4}
                placeholder={"Find Item"}
              />
            </Box1Styled>
          </Grid>
          <Grid item xs={6}>
            <Box
              width="100%"
              sx={{
                display: { xs: "block", sm: "none", md: "none", lg: "none" },
              }}
            >
             <Select
          
                className="selectStyle"
                styles={CustomStyles}
                options={props.options}
                value={selectedValue}
                onChange={props.HandleChange}
                onInputChange={props.handleInputChange}
                getOptionLabel={(option) => option.Column4}
                placeholder={"Find Item"}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5} sm={3} lg={3} md={3}>
            <BoxField
              degradation={false}
              selectedvalue={selectedValue.Column1}
            />
          </Grid>
          <Grid item xs={6} sm={4} lg={3} md={4}>
            <BoxField
              degradation={degradation}
              selectedvalue={selectedValue.Column3}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4}>
            <Box
              width="100%"
              textAlign={"center"}
              sx={{
                display: { xs: "none", sm: "block", lg: "block", md: "block" },
              }}
            >
              <Select
                className="selectStyle"
                styles={CustomStyles}
                options={props.options}
                value={selectedValue}
                onChange={props.HandleChange}
                onInputChange={props.handleInputChange}
                getOptionLabel={(option) => option.Column4}
                placeholder={"Find Item"}
              />
            </Box>
          </Grid>
        </Grid>
        <Box
          margin="6px 10px 6px 20px"
          sx={{ display: { xs: "block", sm: "none", md: "none", lg: "none" } }}
        >
          <CustomSlider1
            width={"90%"}
            display={Adj}
            orientation={"horizontal"}
            size={"small"}
            color={"primary"}
            SliderName={"Capacity Adj"}
            value={capacity}
            min={-500}
            max={500}
            onChange={(e) =>
              dispatch(SliderCapacity(e.target.value))
            }
          />

          <MidContent
            label16={props.label16}
            label16_1={props.label16_1}
            label14={props.label14}
            label14_2={props.label14_2}
            label14_1={props.label14_1}
            label12={props.label12}
            label15_1={props.label15_1}
            label15={props.label15}
          />

          <CustomSlider1
            display={Adj}
            SliderName={"Eff Adj"}
            width={"90%"}
            size={"small"}
            value={EffAdj}
            min={-500}
            max={500}
            onChange={(event) =>
              dispatch(SlideEffAdj(event.target.value))
            }
          />
          <CustomSlider1
            display={true}
            width={"90%"}
            value={slider3}
            min={-200}
            max={100}
            onChange={(e) =>
              dispatch(Slideslider3(e.target.value))
            }
          />
          <Grid container textAlign={"center"}>
            <Grid item md={4} xs={4} sm={4} lg={4}>
              <h5>$ Per/Kwr</h5>
              <h5>{props.label17_1 ? props.label17_1 : 0}</h5>
            </Grid>
            <Grid item md={4} xs={4} sm={4} lg={4}>
              <h5>Chiller $</h5>
              <h5>{props.label30_2 ? props.label30_2 : 0}</h5>
            </Grid>
            <Grid item md={4} xs={4} sm={4} lg={4}>
              <h5>$ Per/Ton</h5>
              <h5>{props.label17_2 ? props.label17_2 : 0}</h5>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <LgScreen
        label30_2={props.label30_2}
        label16={props.label16}
        label16_1={props.label16_1}
        label17_1={props.label17_1}
        label17_2={props.label17_2}
        label14={props.label14}
        label14_1={props.label14_1}
        label14_2={props.label14_2}
        label12={props.label12}
        label15_1={props.label15_1}
        label15={props.label15}
      />
    </Box>
  );
};

export default Box1cop;
