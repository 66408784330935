
import axios from 'axios';
import { Container, Link as Linked } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import { useState } from 'react';
import AppBar from '../../AppBar/AppBar';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Linked color="inherit" href="https://www.danfoss.com/en-au/">
                Danfoss
            </Linked>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


export default function SignUp() {
    const [first_name, setfname] = useState('');
    const [last_name, setLname] = useState('')
    const [email1, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [Success, setSuccess] = useState("");


    const email = email1.toLowerCase()

    const handleSubmit = async (event) => {
        setLoading(true);

        event.preventDefault();

        const config = {
            header: {
                "Content-Type": "application/json"
            }
        }
        if (password !== password2) {
            setPassword("");
            setPassword2("");
            setTimeout(() => {
                setError("")
            }, 5000);
            return setError('Password do not match')
        }

        try {
            const { data } = await axios.post(process.env.REACT_APP_API_URL_USER + "register/", { first_name, last_name, password, email, password2 }, config);
            localStorage.setItem("authToken", data.access);
            setSuccess(data.message)
            setLoading(false);
        } catch (error) {
         setLoading(false);
         setError(error.request.response)
            setTimeout(() => {
                setError("")
                setSuccess("")
            }, 5000)
        }

    };

    // https://dangossserver.onrender.com/api/auth/register"

    return (
        <>
            <AppBar headline={
                <h1 className='headingStyle'>
                    Register Page
                </h1>
            } />

            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Grid container justifyContent='center'>
                        <img src='../images/Icon.png' alt='Icon' width='200px' />
                    </Grid>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    {Success && <span>{Success} <Link to="/login">
                
                login
            </Link>
        
            </span>
            }
                    {error && <span>{error}</span>}
                    <Box component='form' onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                required
                                    color='secondary'
                                    value={first_name}
                                    onChange={e => setfname(e.target.value)}
                                    autoComplete="given-name"
                                    name="first_name"
                                   
                                    fullWidth
                                    id="first_name"
                                    label="first_name"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                            
                                    color='secondary'
                                    value={last_name}
                                    onChange={e => setLname(e.target.value)}
                                    autoComplete="given-name"
                                    name="lname"
                                    required
                                    fullWidth
                                    id="last_name"
                                    label="last_name"
                                    autoFocus
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    color='secondary'
                                    type="email"
                                    value={email1}
                                    onChange={e => setEmail(e.target.value)}
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    color='secondary'
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    color='secondary'
                                    required
                                    fullWidth
                                    name="password"
                                    label="Confirm Password"
                                    type="password"
                                    id="Confirmpassword"
                                    value={password2}
                                    onChange={e => setPassword2(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox color='secondary' value="allowExtraEmails" />}
                                    label="I want to receive inspiration, marketing promotions and updates via email."
                                />
                            </Grid>
                        </Grid>
                        <LoadingButton
                            sx={{ mt: 3, mb: 2 }}
                            color='tertiary'
                            fullWidth
                            size="small"
                            onClick={handleSubmit}
                            loading={loading}
                            variant="contained"
                        >
                            <Typography color='primary' >Sign Up</Typography>
                        </LoadingButton>

                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link to="/login" variant="body2">
                                    Already have an account? Log In
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>

                </Box>
                <Copyright sx={{ mt: 5 }} />

            </Container>
        </>

    );
}