import React from "react";
import { Box, Slider, Grid } from "@mui/material";
import "../CSS/index.css";


const CustomSlider = (props) => {
    return <>
    <h5 style={{margin:props.TypoMargin}}>{props.SliderName || null}</h5>
        <Grid container>
            <Grid item xs={8} md={8} sm={8} lg={8}>
                <Box width='100%' height='100%' sx={{mt: '9%'}}  >
                    <Box height={props.height} width={props.width} textAlign={props.textAlign || null} >
                        <Slider
                          m={props.m}
                            color={props.color}
                            size={props.size}
                            orientation="vertical"
                            value={props.value}
                            min={props.min}
                            max={props.max}
                            onChange={props.onChange}
                            valueLabelDisplay="auto"
                            marks={props.marks}
                        />
                    </Box>
                    <h6 style={{marginTop:'10px'}}>{props.BottomName || null}</h6>
                    <h6>{props.BottomValue || null}</h6>
                </Box>
            </Grid>
            <Grid item xs={4} md={4} sm={4} lg={4}>
                <Box marginTop={props.marginTop}>
                    <Box display={props.unitnameDisplay}>
                        <h6>{props.unitname}</h6>
                    </Box>
                    <Box display={props.unitnameDisplay}>
                        <h6>{props.unitvalue}</h6>
                    </Box>
                </Box>

                <Grid container>
                    <Grid item sm={6} lg={6} md={6} xs={6}>
                        <h6>{props.valuename2}</h6>
                    </Grid>
                     <Grid item md={12} sm={12} xs={12} lg={12}>
                        <h6>{props.value2}</h6>
                     </Grid>
        </Grid>
        </Grid>
        </Grid>

    </>
}


export default CustomSlider;
