import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    PerKWdata:[],
    selectedPerKW:{
        Column1: "Sudan",
        Column2: "0.00",
        PowerAppsId: "C6YHhY-UtiA"
      },
    AdminSelectPerKw:{
        Column1: "Sudan",
        Column2: "0.00",
        PowerAppsId: "C6YHhY-UtiA"
    },
    InputValue4:''
}


const url = process.env.REACT_APP_API_URL_DATA + "perkwdata/"

console.log(url)


export const getPerkwData= createAsyncThunk('valueTool/getPerKW', async (object, { getState, rejectWithValue }) => {

      const { data } = await axios.get(
        url, {
            headers: {
            "Content-Type": "application/json",
              "Authorization": "Bearer " + localStorage.getItem('authToken')
            }
          }
      );
      return data;

    } 
)


const selectPerkw = createSlice({
    name:'PerKW',
    initialState,
    reducers:{
        ModelSelectedPerKwData:(state, action)=> {
            state.selectedPerKW = action.payload
        } ,
        ModelAdminSelectedPerKwData:(state, action)=> {
            state.AdminSelectPerKw = action.payload
        } ,
        ModelInputValue4:(state, action)=>{
            state.InputValue4 = action.payload
        }
    },
    extraReducers:(builder) => {
        builder.addCase(getPerkwData.fulfilled, (state, { payload }) => {
            state.PerKWdata = payload
            state.isloading = true
        })}
},)

export const {ModelSelectedPerKwData, ModelInputValue4, ModelAdminSelectedPerKwData} = selectPerkw.actions

export default selectPerkw.reducer