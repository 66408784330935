
import { Stack,Slider , Box} from "@mui/material";
import "../../CSS/index.css";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from "react-redux";
import { SlideSlider6_22} from "../../Redux/Components/OFC/OFCSlice";

const OFCFirstBar=()=>{

    const { slider6_22, OilFreeMetric} = useSelector((store)=>store.OFC);
    const dispatch = useDispatch()
    return (
        <>
        <Box  textAlign='center' sx={{display:{xs:'none', sm:'none', md:'block', lg:'block'}, paddingLeft:'15px'}}>
            <Stack direction={'row'}>
                <Stack direction={'column'} spacing={2} >
                <KeyboardArrowUpIcon color={'primary'}  style={{fontSize:'60px'}}/>
                        <h6 style={{margin:'160px 0'}}> Plant Level</h6>
                        <KeyboardArrowDownIcon color={'primary'} style={{fontSize:'60px'}} />
                        <h6> TG520</h6>
                        <h6> TG390</h6>
                        <h6> TG230</h6>
                </Stack>
                <Box height={'75vh'}>
                    <h5>Capacity</h5>
                    <Slider value={slider6_22}
                    min={70}
                    max={700}
                    color={'primary'}
                    size={'medium'}
                    orientation={'vertical'}
                    onChange={e=> dispatch(SlideSlider6_22(e.target.value))}
                    />
                     <h5 style={{marginTop:'10px'}}>
                     {OilFreeMetric === true ? 'Kwr' : 'Tn'}
                    </h5>
                    <h5>
                    {OilFreeMetric === true ? (Math.round(slider6_22*3.5)) : slider6_22}
                    </h5>
                </Box>
                 <Stack direction={'column'} spacing={2}>
                        <h5 style={{ margin: '480px 0 60px 0' }}> TT700</h5>
                        <h5> TT400</h5>
                        <h5> TT300</h5>
                    </Stack>
                   
            </Stack>
           
        </Box>
        </>
    )
}
export default OFCFirstBar;