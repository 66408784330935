import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState={
    UserData :{},
    isadmin:false,
    first_name:'',
    name_initial:''
}



const url = process.env.REACT_APP_API_URL_USER + 'me/'

export const getUserData= createAsyncThunk('user/Data', async (object, { getState, rejectWithValue }) => {

    const { data } = await axios.get(
      url, {
          headers: {
          "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('authToken')
          }
        }
    );
    return data;
    

  } 
)


const userdata = createSlice({
    name:'userData',
    initialState,
    reducers:{
    },
    extraReducers:(builder) => {
        builder.addCase(getUserData.fulfilled, (state, { payload }) => {
            state.UserData = payload
            if(state.UserData.is_admin === true){
                state.isadmin = true
            }
            if(state.UserData.first_name){
                state.first_name = state.UserData.first_name
            }
            if(state.first_name){
                state.name_initial = state.first_name.charAt(0).toUpperCase()
            }
            
        })}
})



export default userdata.reducer