

import React , {useState, useEffect}from 'react';
import { Box, Stack , Divider, Alert} from '@mui/material';
import { StyledButton } from '../customComponent/StyledButton';
import "../CSS/Home.css"
import {  Grid } from "@mui/material";
import {  Link , useNavigate} from "react-router-dom";
import AccountMenu from '../screens/profile/ProfileScreen';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';


const AppBar = (props) => {



    const authToken = localStorage.getItem('authToken')

  return (
    <Box bgcolor='#B0000F' sx={{  width:'100vw' }}  >



    <Grid container sx={{p:'1%'}}>
        <Grid item xs={5} sm={3} md={3} lg={3}>
            <Grid container>
                <Grid item xs={7} sm={7} md={4} lg={4}>
                    <Box display='flex' height='100%' width='100%'>
                        <img src="../images/danfoss.png" alt="Danfoss"  width="100%" />
                    </Box>
                </Grid>
                <Grid item xs={5} sm={5} md={2} lg={2}>
                    <Box sx={{ height: '100%', width: '100%' }}>
                        <h4>ENGINEERING</h4>
                        <h4>TOMORROW</h4>
                    </Box>

                </Grid>
            </Grid>

        </Grid>
        <Grid item xs={1} sm={5} md={5} lg={5} >
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <h1 className="headingStyle" style={{marginLeft:'20%'}}>{props.headline}</h1>
            </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4}>
        { authToken ?  <Box sx={{ textAlign: 'end'}}>
               
                   <StyledButton sx={{mr:'2%', ":hover":{bgcolor:'#B0000F'}, outline:'none'}}  >
                   <AccountMenu/>
                   </StyledButton>
               
               <Link to='/home' style={{ textDecoration: 'none' }} >
               <StyledButton sx={{ padding:'11px'}} className='LogoutBtn'>
           <h5 style={{marginRight:'5%', display:{xs:'none'}}}>Return</h5>
      {<KeyboardReturnIcon color='primary' />}
   </StyledButton>
   </Link>

           </Box>
            : 
            <Stack direction={'row'} gap={2} sx={{pt:'1%', pl:'5%'}}   divider={<Divider orientation="vertical" sx={{bgcolor:'white'}} flexItem />}>
           <Link to="/login" style={{ textDecoration: 'none' }} > <StyledButton sx={{outline:'1px solid white' , borderRadius:'20px'}}>Login</StyledButton> </Link>
           <Link to="/register" style={{ textDecoration: 'none' }} >      <StyledButton sx={{outline:'1px solid white' , borderRadius:'20px'}}>Register</StyledButton></Link>
            </Stack>}
        </Grid>
        <Grid item xs={12} sm={false} lg={false} md={false} >
                        <Box height='100%' width='100%' sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }} >
                            {props.headline}
                        </Box>

                    </Grid>
    </Grid>

</Box>

  )
}

export default AppBar