import { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {Container, Typography, Link as Linked} from "@mui/material"
import AppBar from '../../AppBar/AppBar';

const ForgotPasswordScreen = () => {

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [Success, setSuccess] = useState("");
    

    function Copyright(props) {
        return (
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                {'Copyright © '}
                <Linked color="inherit" href="https://www.danfoss.com/en-au/">
                    Danfoss
                </Linked>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        );
    }



    const forgotpasswordHandler = async (e) => {
        e.preventDefault();
        const config = {
            Headers: {
                "Content-Type": "application/json"
            }
        };
        try {
            const { data } = await axios.post(process.env.REACT_APP_API_URL_USER + "send-reset-password-email/", { email }, config);
            setSuccess(data.message)
            
        } catch (error) {

            setError(error.response.data.errors.non_field_errors[0]);

            setTimeout(() => {
                setError("")
            }, 5000)
        }
    }

    return (
        <>
                <AppBar headline ={
            <h1 className='headingStyle'>
                Forgot Password Page
            </h1>
        }/>
    
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >


                <Grid container justifyContent='center' >
                    <img src='../images/Icon.png' alt='Icon' width='200px' />
                </Grid>
                <Typography component="h1" variant="h5">
                    Forgot Password
                </Typography>
                {error && <span>{error}</span>}
                {Success && <span>{Success} <Link to="/login">
                
                    login
                </Link>
            
                </span>
                }
                <Box  sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField 
                            color="secondary"
                                type="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>
                    </Grid>
                    <Button
                    onClick={forgotpasswordHandler}
                    color='tertiary'
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                    <Typography color='primary'> Send Email</Typography>
                    </Button>

                </Box>

            </Box>
            <Copyright sx={{ mt: 5 }} />

        </Container>
        </>
    )
}

export default ForgotPasswordScreen;