import React, { useContext } from "react";
import { Grid, Box } from "@mui/material";
import CustomSlider from "../../../../customComponent/Customslider";
import { useSelector, useDispatch } from "react-redux";
import { HydroCapacitySlider, HydroChillerSlider, HydroPerKWSlider, HydroPerHourSlider} from "../../../../Redux/Components/HydroPlant/HydroPlantSlice";

const HydroBox1 = (props) => {
    const {
        Hydrocapacity, Hydrochiller, HydroPerKW, HydroPerHour } = useSelector((store)=>store.hydroplant);
const dispatch = useDispatch()
    return (
        <>
<Box sx={{display:{xs:'none', sm:'none', md:'block', lg:'block'}}} position={'relative'} zIndex={2}>
            <Grid container style={{textAlign:'center'}}>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                    <CustomSlider
                    marginTop={'100px'}
                        SliderName={'Capacity Adj'}
                        height={306}
                        value={Hydrocapacity}
                        min={100}
                        max={1000}
                        onChange={(e) => dispatch(HydroCapacitySlider(e.target.value))}
                        BottomName={"Kwr"}
                        BottomValue={Math.round(Hydrocapacity * 3.5)}
                        unitname={'Ton'}
                        unitvalue={Hydrocapacity}

                    />
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                    <CustomSlider
                    marginTop={'100px'}
                        SliderName={'Chiller COP'}
                        height={306}
                        value={Hydrochiller}
                        min={25}
                        max={99}
                        onChange={e => dispatch(HydroChillerSlider(e.target.value))}
                        BottomName={"Kw/Kw"}
                        BottomValue={props.label61_2}
                        unitvalue={Hydrochiller / 100}

                    />
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                    <CustomSlider
                        SliderName={'$ per KW'}
                        marginTop={'100px'}
                        height={306}
                        value={HydroPerKW}

                        min={1}
                        max={30}
                        onChange={e => dispatch(HydroPerKWSlider(e.target.value))}
                        unitvalue={HydroPerKW / 100}
                    />
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                    <CustomSlider
                    marginTop={'100px'}
                        SliderName={'Run Hours Per Year'}
                        height={306}
                        value={HydroPerHour}
                        min={0}
                        max={8760}
                        onChange={e => dispatch(HydroPerHourSlider(e.target.value))}
                        unitvalue={HydroPerHour}

                    />
                </Grid>


            </Grid>
            </Box>
        </>
    )
}
export default HydroBox1;