import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useState} from "react";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ProfilePage from "./ProfilePage";
import SettingPage from "./SettingPage";
import {  Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [openDialog, setOpen] = useState(false);
  const [openSettingDialog, setSettingDialog] = useState(false)
  const {isadmin, name_initial} = useSelector((store)=>store.userinfo)
 
  const navigate = useNavigate()

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

 

  const handleClose = () => {
    setAnchorEl(null);

  };

  const handleClickProfile = () => {
    setOpen(true);
  };

  function handlebuttonClose() {
    setOpen(false);
   
  }


  function settingButtonClose(){
    setSettingDialog(false)
  
  }

  function handleClickSetting(){
    setSettingDialog(true)
  }
  

  const handleLogout =(e)=>{
    e.preventDefault();
    try {
        localStorage.removeItem("authToken");
        sessionStorage.removeItem("disclaimer")
        navigate("/landing");
        window.location.reload();

    } catch (error) {
        console.log(error)
    }

}





  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{name_initial ? name_initial : 'A'}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClickProfile}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClickSetting}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Change Password
        </MenuItem>
        {isadmin ?  <Link to='/admin' style={{ textDecoration: 'none' }}> <MenuItem >
          <ListItemIcon>
            <AdminPanelSettingsIcon fontSize="small" />
          </ListItemIcon>
          Admin
        </MenuItem> </Link>  : null }
       
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
        <ProfilePage openDialog={openDialog} handlebuttonClose={handlebuttonClose}/>
        <SettingPage openSettingDialog={openSettingDialog} settingButtonClose={settingButtonClose}/>
    </React.Fragment>
  );
}
