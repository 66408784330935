import React from "react";
import "../../../../CSS/index.css";
import SmallBox from "../../Smallcomponents/Smallbox";
import { Grid } from "@mui/material";
import CustomSlider1 from "../../../../customComponent/CustomSlider1.js";
import { useDispatch, useSelector } from "react-redux";
import { Slideslider3 } from "../../../../Redux/Components/Chiller/ChillerSlice";

const Box1ThirdCompt = (props) => {
    
    const {
        slider3,
    } = useSelector((store)=>store.chiller);
    const dispatch = useDispatch()

    return (
        <>
            <Grid container >
                <Grid item md={1} sm={1} lg={1}>
                    <CustomSlider1
                    m={'30px 0 0 0'}
                    orientation={'vertical'}
                    display={true}
                        height={251}
                        width={99}
                        value={slider3}
                        min={-200}
                        max={100}
                        onChange={e => dispatch(Slideslider3(e.target.value))}
                    />
                </Grid>
                <Grid item md={5} sm={5} xs={5} lg={5} style={{ marginLeft: '60px' }}>
                    <Grid container style={{ marginTop: 20}}>
                        <Grid item md={12} sm={12} xs={12} style={{ marginTop: '10px' }}>
                            <SmallBox
                                Value={props.label17_1 ? props.label17_1 : 0}
                                Valuename={"$Per/Kwr"}
                            />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} style={{ marginTop: '60px' }}>
                            <Grid container>
                                <Grid item xs={8} sm={8} md={10} lg={10}>
                                    <h5>$Chiller</h5>
                                </Grid>
                                <Grid item xs={4} sm={4} md={2} lg={2}>
                                    <h5>{props.label30_2 ? props.label30_2 : 0}</h5>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} style={{ marginTop: "85px" }}>
                            <SmallBox
                                Value={props.label17_2 ? props.label17_2 : 0}
                                Valuename={"$Per/Ton"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}
export default Box1ThirdCompt;