import React, { useContext } from "react";
import MidComptGird from "../../Smallcomponents/MidComptGrid";
import { Grid, Box } from "@mui/material";
import "../../../../CSS/index.css";
import { useSelector } from "react-redux";

const MidContent = (props) => {
   
    const {
        capacity,
        EffAdj,
        metric,
        imperial

    } = useSelector((store)=>store.chiller);


    // formula

    const label16 = props.label16;
    const label16_1 = props.label16_1
    const label14 = props.label14
    const label14_2 = props.label14_2
    const label14_1 = props.label14_1
    const label12 = props.label12
    const label15_1 = props.label15_1
    const label15 = props.label15
    return (
        <>
            <Grid container style={{marginTop:'20px'}}>

              
                <Box sx={{ height: '100%', width: '100%', textAlign: 'center', m: "2% 0"}}   display={metric === true ? 'block' : 'none'} >
                    <Grid container>
                        <MidComptGird
                            valuename={"Chiller 1 Kwr"}
                            value={label14 ? label14 : capacity}
                        />
                        <MidComptGird
                            valuename={"Full load"}
                            value={label14_2 ? label14_2 : 0}
                        />
                        <MidComptGird
                            valuename={"IPLV Kw/Kwr"}
                            value={(label14_1 ? (label14_1).toFixed(2) : (EffAdj / 100))}
                        />

                    </Grid>
                </Box>
                
                <Box sx={{ textAlign: 'center', width: '100%', height: '100%', m: "3% 0%" }}  display={imperial === true ? 'block' : 'none'}>
                    <Grid container>
                        <MidComptGird
                            valuename={"Chiller TR"}
                            value={Math.round(label12 ? label12 : 0)}
                        />
                        <MidComptGird
                            valuename={"Full load"}
                            value={label15_1 ? label15_1.toFixed(2) : 0}
                        />
                        <MidComptGird
                            valuename={"IPLV Kw/Kwr"}
                            value={label15 === 'NaN' ?  0 : label15}
                        />
                    </Grid>
                </Box>
                <Grid item md={6} xs={6} sm={6} lg={6}>
                    <h6>Kw/hr</h6>
                    <h6>{label16 ? label16 : 0}</h6>
                </Grid>
                <Grid item md={6} xs={6} sm={6} lg={6}>
                <h6>Co2 Emissions Tons</h6>
                <h6>{label16_1 ? label16_1 : 0}</h6>
                </Grid>
            </Grid>

        </>
    )
}

export default MidContent;



