import React from "react";
import { Grid } from "@mui/material";
import "../../../CSS/index.css";


const MidComptGird =(props)=>{
    return (
        <>
            <Grid item md={4} xs={4} sm={4} lg={4} >
                <h5>{props.valuename}</h5>
                <h6>{props.value}</h6>
                </Grid>
        </>
    )
}
export default MidComptGird;