import React from "react";
import { Box, Slider, styled} from "@mui/material";
import "../CSS/index.css";


const WhiteSlider = styled(Slider)(({ theme }) => ({
    "& .MuiSlider-markLabel": {
      color: "white"
    },
    "& .MuiSlider-mark": {
      backgroundColor: "white"
    }
  }));


const CustomSlider1 = (props) => {
    return <>
    <Box width='100%' height='100%' display={ props.display === true ? 'block' : 'none'}>
        <h1 className="topicTypo" style={{margin:props.topicMargin}}>{props.SliderName || null}</h1>
                    <Box sx={{height:props.height, width:props.width, m:props.m, textAlign:'center'}}>
                        <WhiteSlider
                        marks={props.marks}
                            color={props.color}
                            size={props.size}
                            orientation={ props.orientation}
                            value={props.value}
                            min={props.min}
                            max={props.max}
                            onChange={props.onChange}
                            valueLabelDisplay="auto"
                        />
                </Box>
                <h6>{props.BottomValue || null}</h6>
                <h6>{props.BottomName || null}</h6>
        </Box>
    </>
}


export default CustomSlider1;