
import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    OilFreeMetric:false,
    CheckValve:true,
    Features:false,
    Components:false,
    slider6_22: 200,
    slider6_23:40,
    slider6_24:14,
    slider6_25:5000,
    slider6_26:0,
}

const OFCSlice = createSlice({
    name:'OFC',
    initialState,
    reducers:{
        ModelCheckValve:(state, action)=>{
            state.CheckValve = action.payload
        },
        ModelFeatures:(state, action)=>{
            state.Features = action.payload
        },
        ModelComponents:(state, action)=>{
            state.Components = action.payload
        },
        SlideSlider6_22:(state, action)=>{
            state.slider6_22 = action.payload
        },
        SlideSlider6_23:(state, action)=>{
            state.slider6_23 = action.payload
        },
        SlideSlider6_24:(state, action)=>{
            state.slider6_24 = action.payload
        },
        SlideSlider6_25:(state, action)=>{
            state.slider6_25 = action.payload
        },
        SlideSlider6_26:(state, action)=>{
            state.slider6_26 = action.payload
        },
        ModelOilFreeMetric:(state,action)=>{
            state.OilFreeMetric = action.payload
        }
    }

})


export const {ModelCheckValve, ModelComponents, ModelFeatures, ModelOilFreeMetric, SlideSlider6_22, SlideSlider6_23, SlideSlider6_24, SlideSlider6_25, SlideSlider6_26} = OFCSlice.actions
export default OFCSlice.reducer