import React from "react";
import { Grid } from "@material-ui/core";
import "../../../CSS/index.css";

const SmallBox = (props) => {
    return (
        <>
            <Grid container>
                <Grid item md={6} xs={12} sm={12} lg={6}>
                    <h5>{props.Value}</h5>
                </Grid>
                <Grid item md={6} xs={12} sm={12} lg={6}>
                    <h5>{props.Valuename}</h5>
                </Grid>
            </Grid>

        </>
    )
}

export default SmallBox;