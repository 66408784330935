import React, { useState } from 'react'
import AppBar from '../AppBar/AppBar'

import CurrencyForm from './Currency'
import PerKwForm from './PerKWForm'
import PowerAppForm from './PowerAppForm'
import { Box, Stack } from '@mui/material'
import CustomStyles from '../customComponent/DDownStyles'
import Select from 'react-select'


const AdminPage = () => {

 const [selectedValue , setSelectedValue] = useState({})

      
    const options = [
        { value: 'PowerApp', label: 'PowerApp' },
        { value: 'PerKW', label: 'PerKW' },
        { value: 'Currency', label: 'Currency' }
      ]

  return (
<>
    <AppBar
    headline={
          <Stack direction={'column'}>
            <h1 className='headingStyle'> Admin Panel</h1>
            <Select  options={options} value={selectedValue} styles={CustomStyles} onChange={(newValue)=> setSelectedValue(newValue)}/>
          </Stack>
    }
    />
    <Box className='main'>
     {selectedValue.value === 'PowerApp' ? <PowerAppForm/> : null}
     {selectedValue.value === 'PerKW' ? <PerKwForm/> : null}
    
     {selectedValue.value === 'Currency' ? <CurrencyForm/> : null}
     
    </Box>
  
    </>  )
}

export default AdminPage