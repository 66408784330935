import React from "react";
import CustomSlider from "../../../customComponent/Customslider";
import CustomHorizontalSlider from "../../../customComponent/CstmHozSlider";
import "../../../CSS/index.css";
import { Stack,Grid, Box } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useDispatch, useSelector } from "react-redux";
import {SlideSlider6_23, SlideSlider6_22, SlideSlider6_24, SlideSlider6_25} from '../../../Redux/Components/OFC/OFCSlice';


const OFCBox1 = (props) => {
    
    const { slider6_23, slider6_24, slider6_25, slider6_22, OilFreeMetric } = useSelector((store)=>store.OFC);
    const dispatch = useDispatch()


    return (
        <>
            <Box margin={'0 2% 0 5%'} sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }}>
                <Stack direction={'row'} spacing={2} >
                    <ArrowBackIosNewIcon fontSize="60" color={'primary'} />
                    < h6 style={{ margin: '0 12%' }}> Plant Level</h6>
                    <ArrowForwardIosIcon color={'primary'} fontSize="60" />
                    <h6> TG520</h6>
                        <h6> TG390</h6>
                        <h6> TG230</h6>
                </Stack>
                <CustomHorizontalSlider
                    SliderName={'Capacity'}
                    display={true}
                    width={'93%'}
                    value={slider6_22}
                    min={70}
                    max={700}
                    onChange={e => dispatch(SlideSlider6_22(e.target.value))}
                    // BottomValue={Math.round(slider6_22 * 3.5)}
                    BottomValue={OilFreeMetric === true ? 'Kwr' : 'Ton'}
                    unitvalue={OilFreeMetric === true ? Math.round(slider6_22 * 3.5) : slider6_22}
                />
                <Stack direction={'row'} spacing={2}>
                <h5 style={{ margin: '0 6% 0 48%' }}> TT700</h5>
                        <h5> TT400</h5>
                        <h5> TT300</h5>

                </Stack>


                <CustomHorizontalSlider
                    SliderName={'Chiller IPLV'}
                    display={true}
                    width={'93%'}
                    value={slider6_23}
                    min={25}
                    max={99}
                    onChange={e => dispatch(SlideSlider6_23(e.target.value))}

                    BottomValue={OilFreeMetric === true ? "Kw/Kw" : "Kw/Tn"}
                    unitvalue={OilFreeMetric === true ? props.label61_4 : (slider6_23 / 100)}
                />


                <CustomHorizontalSlider
                    display={true}
                    SliderName={'$ per KW'}
                    width={'93%'}
                    value={slider6_24}
                    min={1}
                    max={25}
                    onChange={e => dispatch(SlideSlider6_24(e.target.value))}
                    BottomValue={'$ per KW'}
                    unitvalue={slider6_24 / 100}
                />

                <CustomHorizontalSlider
                    display={true}
                    SliderName={'Run Hours Per Year'}
                    width={'93%'}
                    value={slider6_25}
                    min={1}
                    max={8760}
                    onChange={e => dispatch(SlideSlider6_25(e.target.value))}
                    BottomValue={'Hours'}
                    unitvalue={slider6_25}
                />


            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }} position={'relative'} zIndex={2}>
                <Grid container style={{ textAlign: 'center' }}>
                    <Grid item md={4} lg={4}>
                        <CustomSlider
                            SliderName={'Chiller IPLV'}
                            height={274}
                            value={slider6_23}
                            min={25}
                            max={99}
                            onChange={(e) => dispatch(SlideSlider6_23(e.target.value))}
                            BottomName={OilFreeMetric === true ? "Kw/Kw" : "Kw/Tn"}
                            BottomValue={OilFreeMetric === true ? props.label61_4 : (slider6_23 / 100)}
                        />
                    </Grid>


                    <Grid item md={4} lg={4}>
                        <CustomSlider
                            SliderName={'$ Per KW'}
                            height={274}
                            value={slider6_24}
                            min={1}
                            max={25}
                            onChange={(e) => dispatch(SlideSlider6_24(e.target.value))}
                            BottomName={"$ per Kw"}
                            BottomValue={slider6_24 / 100}
                        />
                    </Grid>


                    <Grid item md={4} lg={4}>
                        <CustomSlider
                            SliderName={'Run Hours Per Year'}
                            height={274}
                            value={slider6_25}
                            min={1}
                            max={8760}
                            onChange={(event) => dispatch(SlideSlider6_25(event.target.value))}
                            BottomName={"Hours"}
                            BottomValue={slider6_25}
                        />
                    </Grid>


                </Grid>
            </Box>
        </>
    )
}
export default OFCBox1;