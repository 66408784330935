import { createSlice } from "@reduxjs/toolkit";


const initialState = { 
    LowLiftApp:true,
    LowLiftMetric:false,
    slider6_8 : 500,
    slider6_9 : 40,
    slider6_10: 14,
    slider6_11: 5000,
    slider6_12 : 12,
    slider6_13 : 29,
    slider7: 9,
}

const LowLiftSlice = createSlice({
    name:'LowLift',
    initialState,
    reducers:{
        SlideSlider6_8:(state, action)=>{
            state.slider6_8 = action.payload
        },
        SlideSlider6_9:(state, action)=>{
            state.slider6_9 = action.payload
        },
        SlideSlider6_10:(state, action)=>{
            state.slider6_10 = action.payload
        },
        SlideSlider6_11:(state, action)=>{
            state.slider6_11 = action.payload
        },
        SlideSlider6_12:(state, action)=>{
            state.slider6_12 = action.payload
        },
        SlideSlider6_13:(state, action)=>{
            state.slider6_13 = action.payload
        },
        SlideSlider7:(state, action)=>{
            state.slider7 = action.payload
        },
        ModelLowLiftApp:(state, action)=>{
            state.LowLiftApp = action.payload
        },
        ModelLowLiftMetric:(state, action)=>{
            state.LowLiftMetric = action.payload
        }

    }
});
export const {SlideSlider6_8, SlideSlider6_9 ,SlideSlider6_10, SlideSlider6_11, SlideSlider6_12, SlideSlider6_13, SlideSlider7, ModelLowLiftApp, ModelLowLiftMetric}= LowLiftSlice.actions;
export default LowLiftSlice.reducer;