import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState={
    data :[],
    selectedValue2: {
        Column1: "Water Cooled",
        Column2: "Generic",
        Column3: "Screw VSD Water Cooled",
        Column4: "Screw VSD Water Cooled",
        Column5: 1000,
        Column6: 5.85,
        Column7: 9.55,
        PowerAppsId: "70f0niWUCXo"
    },
    isloading:false,
    InputValue2:''
}


const selectedValue2 = createSlice({
    name:'selectedItem',
    initialState,
    reducers:{
        ModelInput2:(state, action)=>{
            state.InputValue2 = action.payload
        },
        ModelSelectedValue2:(state, action)=> {
            state.selectedValue2 = action.payload
        }   },
})


export const {ModelInput2, ModelSelectedValue2} = selectedValue2.actions
export default selectedValue2.reducer