
import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import axios from 'axios';
import { styled, AppBar as BottomBar, Grid, Box, Toolbar } from "@mui/material";
import './Admin.css'
import CustomStyles from '../customComponent/DDownStyles';
import AppBar from '../AppBar/AppBar';
import Select from 'react-select';
import { ModelAdminSelectedPerKwData, getPerkwData } from '../Redux/ApiCall/SelectedPerkw';
import { useDispatch, useSelector } from 'react-redux';

const PerKwForm = () => {


  const [formdata , setformData] = useState({Column1:'', Column2:"", PowerAppsId:""})
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [Success, setsuccess] = useState("");



  const Column1 = formdata.Column1
  const Column2 = formdata.Column2
  const PowerAppsId = formdata.PowerAppsId 




  const{
    PerKWdata,
    AdminSelectPerKw,
  } = useSelector((store)=> store.selectedPerkw);

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch((getPerkwData())) 
  },[]);


  const update_id = AdminSelectPerKw.id


  const handleChange = (event) => {
    setformData({ ...formdata, [event.target.name]: event.target.value });
  };


const handleChange2=(newValue)=>{
  dispatch(ModelAdminSelectedPerKwData(newValue))
  setformData(newValue)
}



const url = process.env.REACT_APP_API_URL_DATA + `perkwdata/${update_id}/`



const handleSubmit = async (e) => {
  e.preventDefault();
  const token = localStorage.getItem('authToken')

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  }
  try {
    await axios.put(url, { Column1, Column2, PowerAppsId }, config);
    setsuccess('Succesfully updated')
    setLoading(false);
    setformData("")
  } catch (error) {
    setLoading(false);
    setError(error.request.responseText)

  }
  setTimeout(() => {
    setError("")
    setsuccess("")
    setformData({
      Column1: "",
      Column2: "",
      PowerAppsId: "",
    })
  }, 5000)

}


const url2 = process.env.REACT_APP_API_URL_DATA + `perkwdata/`





  return (
<>

<Box className='Box1'>
  
<h1 className='headingStyle'>PerKW Form</h1>
{Success && <span style={{color:'white'}}>{Success}</span>}
            {error && <span style={{color:'white'}}>{error}</span>}
         <Select 
                      styles={CustomStyles}
                      options={PerKWdata}
                      value={AdminSelectPerKw}
                      onChange={handleChange2}
                      getOptionLabel={(perkw) => perkw.Column1}
                      placeholder={'Find Currency'} />
                      </Box>
    
            <Box className='Box2'>
  <form >
        <TextField
        className='field'
          required
          name='Column1'
          id="Column1"
          label='Country'
          variant='filled'
          value={formdata.Column1}
          onChange={handleChange}      
        />
        <TextField
          required
          className='field'
          name='Column2'
          label='Per Kw'
          variant='filled'
          value={formdata.Column2}
          onChange={handleChange}  
        />

        <TextField
className='field'
          required
          name='PowerAppsId'
          id="name"
          label='Power Id'
          variant='filled'
          value={formdata.PowerAppsId}
          onChange={handleChange} 
        />
  <br/>
      <Button variant="contained" color="primary" type="submit" onClick={handleSubmit}>
        Update
      </Button>

    </form>
    </Box>
</>
    

  );
};

export default PerKwForm;
