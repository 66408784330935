
import { Stack, Box  } from "@mui/material";
import { Context } from "../../../App";
import { useContext } from "react";
import CustomHorizontalSlider from "../../../customComponent/CstmHozSlider";
import "../../../CSS/index.css";
import LgScrnOFCBox3 from "./LgScrnOFCBox3";
import './OFCbox3.css';
import { useDispatch, useSelector } from "react-redux";
import { SlideSlider6_26} from "../../../Redux/Components/OFC/OFCSlice";

const OFCBox3=()=>{
   
    const {slider6_26 , CheckValve , OilFreeMetric, Features , Components} = useSelector((state)=>state.OFC);
    const dispatch = useDispatch()
    return(
        <>
        <Box  sx={{display:{xs:'block', sm:'block', md:'none', lg:'none'}}}  >
                                <Box padding={'4% 3% 0 3%'} display={CheckValve === true ? 'block' :'none'}  width={'90%'}>
                                <h6>  Pressure Drop</h6>
                                    
                                    <Stack direction={'column'} spacing={1} justifyContent='center'>
                                        <Stack direction={'row'} justifyContent='space-between'>
                                        <h6>High</h6>
                                            <h6>Med</h6>
                                            <h6>Low</h6>
                                        </Stack>
    
                                        <CustomHorizontalSlider
                                            display={CheckValve}
                                            value={slider6_26}
                                            width={'100%'}
                                            size={'small'}
                                            min={-75}
                                            max={0}
                                            onChange={e => dispatch(SlideSlider6_26(e.target.value))}
                                            unitvalue={'Chart'}
                                        />
                                        <Stack direction={'row'} justifyContent='space-between'>
                                        <h6>Piston Type</h6>
                                            <h6>Axial Wafer</h6>
                                            <h6>Swing Wafer</h6>
                                            <h6>OFC</h6>
                                        </Stack>
                                    </Stack>
                                    </Box>
                                 
                                   
              
                <div width='100%' height='100%' position={'relative'} style={{marginTop:'15px'}}>
                    <img src="../images/power.png" alt="Plant1" className="HydroPlant5" />
                    <div className="Value1"><h6> {OilFreeMetric === true ? '12.1' : '53.8'} </h6></div>
                    <div className="Value2"><h6> {OilFreeMetric === true ? '6.7' : '44.1'} </h6></div>
                    <div className="Value3"><h6> {OilFreeMetric === true ? '34.5' : '94.1'} </h6></div>
                    <div className="Value4"><h6> {OilFreeMetric === true ? '29.4' : '84.9'} </h6></div>
                    <img src="../images/Checkvalve.png" alt="hydrolic" className="Hydrolic"/>
                    <Box display = { Features === true ? 'block' :'none'}>                
                    <img src="../images/Features.png" alt='Features' className='Features1'/>
                    </Box>
                    <Box display = { Components === true ? 'block' :'none'}>
                    <img  src="../images/Components.png" alt='Components' className='Components1'/>
                    </Box>
                </div>
                </Box>
                <Box sx={{display:{xs:'none', sm:'none', md:'block', lg:'block'}}}>
                   <LgScrnOFCBox3/>
                </Box>
        </>

    )
}
export default OFCBox3;