import React, { useState } from "react";
import {
    Dialog,
    Button,
    Box,
    DialogTitle,
    DialogContent,
    TextField,
    Grid,
    Stack,
    Alert,
} from "@mui/material";
import { StyledButton } from "../../customComponent/StyledButton";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';

const SettingPage = (props) => {
    const [formdata , setformData] = useState({old_password:'', password:"", password2:""})
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [Success, setsuccess] = useState("");


    const old_password = formdata.old_password
    const password = formdata.password
    const password2 = formdata.password2

    const handleChange = (event) => {
        setformData({ ...formdata, [event.target.name]: event.target.value });
      };


    const handleSubmit =async(e)=>{
        e.preventDefault();
        const token = localStorage.getItem('authToken')

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }
        try {
            const {data} =  await axios.patch(process.env.REACT_APP_API_URL_USER +`me/change_password/`, {old_password, password, password2} ,config);
            setsuccess(data.message)
            setLoading(false);
            setformData("")
        } catch (error) {
            setLoading(false);
            console.log(error)
            setError(error.request.responseText)
        }
        setTimeout(() => {
            setError("")
            setsuccess("")
            setformData({old_password:'', password:"", password2:""})
        }, 5000)
       
    }

    return (
        <>
            <Dialog open={props.openSettingDialog} onClose={props.settingButtonClose}>
            <DialogTitle>Change your Account Information</DialogTitle>
            {Success && <span>{Success}</span>}
            {error && <span>{error}</span>}
            <Box className="Box1" >
                <form onSubmit={handleSubmit}>
                    <Stack direction={"column"} gap={2}>
                        <TextField
                            required
                            type={'password'}
                            className="field"
                            label="Old Password"
                            name="old_password"
                            variant="filled"
                            value={formdata.old_password}
                            onChange={handleChange}
                        />
                        <TextField
                            required
                            className="field"
                            label="Password"
                            name="password"
                            variant="filled"
                            type={'password'}
                            value={formdata.password}
                            onChange={handleChange}
                        />
                    </Stack>
                    <TextField
                        required
                        fullWidth
                    
                        className="field"
                        label="password2"
                        name="password2"
                        type={"password"}
                        variant="filled"
                        value={formdata.password2}
                        onChange={handleChange}
                    />

                    <br />
                    <Stack direction={'row'} gap={3} style={{ margin: '25px' }}>
                        <Button
                            variant="filled"
                            style={{ color: "white", backgroundColor: "blue" }}
                            type="submit" onClick={handleSubmit}>
                            Change
                        </Button>
                        <StyledButton
                            variant="filled"
                            style={{ color: "white", backgroundColor: "red" }}
                            onClick={props.settingButtonClose}>
                            Cancel
                        </StyledButton>
                    </Stack>
                </form>
                <Box style={{paddingBottom:'2%'}}>
                <Link to="/forgotpassword" variant="body2">
                                                Forgot password?
                </Link>
                </Box>


            </Box>
            
        
            </Dialog>
    </>
  );
};

export default SettingPage;
