
import React from "react";
import {Box, Grid , Toolbar , Slider, Stack , AppBar as BottomBar} from "@mui/material";
import OFCBox1 from "./OFCBox1/OFCBox1";
import OFCBox2 from "./OFCBox2/OFCbox2";
import OFCFirstBar from "./OFCVerSlider";
import OFCBox3 from "./OFCBox3/OFCbox3";
import OFCBox4 from "./OFCBox4/OFCBox4";
import "../../CSS/index.css";

import CustomCheckBox from "../../customComponent/CustomCheckBox";
import ShowChartIcon from '@mui/icons-material/ShowChart';
import "../../CSS/OFCPage.css";
import { useDispatch, useSelector } from "react-redux";
import { ModelCheckValve,ModelComponents, ModelOilFreeMetric, ModelFeatures,     SlideSlider6_26 } from "../../Redux/Components/OFC/OFCSlice";
import TopBar from "../../AppBar/AppBar";
const OFCPage = () => {

    const { slider6_22, slider6_23, slider6_24, slider6_25, slider6_26, CheckValve,
       Features, Components , OilFreeMetric } = useSelector((store)=> store.OFC);

       const dispatch = useDispatch()

    //Formula for Oil free Valve
    const label44_18 = slider6_23 / 100;
    const label44_19 = slider6_25;
    const label72_1 = .58;
    const label44_7 = slider6_24 / 100;
    const label61_4 = (12 / (label44_18 * 3.412)).toFixed(2);
    const label44_6 = slider6_22;
    const label44 = slider6_26 / 10000;
    const label44_20 = (slider6_23/100) + label44;
    const label48_60 = Math.round(label44_6*3.5);
    const label71_4 = Math.round(label44_6 * label72_1 * label44_18 * label44_7 * label44_19);
    const label71_5 = Math.round(label44_6 * label44_20 * label72_1 * label44_7 * label44_19);
    const label48 = label71_4 - label71_5;


    return (
        <>
            <TopBar headline={
                <h1 className="headingStyle" style={{textAlign:'center'}}>
                   Danfoss Oil-Free Check Valve {
                    <CustomCheckBox
                        checked={CheckValve}
                        onChange={
                            e => dispatch(ModelCheckValve(!CheckValve))
                        }
                    />
                } 
                </h1>
                }
            />
            <Box className="OFCpage"  sx={{paddingBottom:{xs:'320px', sm:'320px', md:'20px', lg:'20px'}, paddingTop:'0.2%',paddingRight:'1%'}}
            >
                <Grid container>
                    <Grid item md={2} lg={2} sm={2} xs={2}>
                        <OFCFirstBar />
                    </Grid>
                    <Grid item md={10} sm={12} xs={12} lg={10}>
                        <Grid container>
                            <Grid item md={4} xs={12} lg={4} sm={12}>
                                <OFCBox1
                                    label61_4={label61_4}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} lg={6} sm={12}>
                                <OFCBox2
                                    label71_5={label71_5}
                                    label71_4={label71_4}
                                    label48={label48}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                                <Box sx={{display:{xs:'none', sm:'none', md:'block', lg:'block'}}}
                                >
                                <Box display={CheckValve === true ? 'block' :'none'} textAlign={'center'} >
                                    <h6>  Pressure Drop</h6>
                                    <Stack direction={'row'} spacing={1} justifyContent='center'>
                                        <Stack direction={'column'} justifyContent='space-between'>
                                            <h6>High</h6>
                                            <h6>Med</h6>
                                            <h6>Low</h6>
                                        </Stack>
                                        <Box height={274}> 
                                        <Slider
                                        sx={{marginTop:'10px'}}
                                            orientation="vertical"
                                            value={slider6_26}
                                            min={-75}
                                            max={0}
                                            valueLabelDisplay="auto"
                                            onChange={e => dispatch(SlideSlider6_26(e.target.value))}
                                        />
                                        </Box>
                                        <Stack direction={'column'} justifyContent='space-between'>
                                            <h6>Piston Type</h6>
                                            <h6>Axial Wafer</h6>
                                            <h6>Swing Wafer</h6>
                                            <h6>OFC</h6>
                                        </Stack>
                                    </Stack>
                                    <ShowChartIcon color={'primary'} style={{ margin:'15px 9% 0 0', fontSize:'50px'}}/>
                                    </Box>
                                    </Box>
                                   
                          
                            </Grid>
                            <Grid item md={5} xs={12} lg={5} sm={12}>
                                <OFCBox3 />
                            </Grid>
                            <Grid item md={12} xs={12} lg={12} sm={12}>
                                <OFCBox4 />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <BottomBar postion='fixed' color="tertiary" sx={{top:'auto',bottom:'0', display:{xs:'block', sm:'block', md:'none', lg:'none'}}}>
              <Toolbar>
              
                     <CustomCheckBox name={"Metric"}
                     checked={OilFreeMetric}
                    onChange= {event => dispatch(ModelOilFreeMetric(!OilFreeMetric))} />


                      <CustomCheckBox name={`Features`} 
                       checked={Features}
                        onChange= {event => dispatch(ModelFeatures(!Features))}
                    />
                     <CustomCheckBox name={`Components`} 
                       checked={Components}
                        onChange= {event => dispatch(ModelComponents(!Components))}
                    />
              </Toolbar>
              </BottomBar>


            </Box>

        </>
    )
}
export default OFCPage;