
import { Checkbox, styled } from "@mui/material";

const CheckBoxStyled = styled(Checkbox)(({theme})=>({
    color:'white',
    '&.Mui-checked':{
        color:'white'
    }
}))

export default CheckBoxStyled;