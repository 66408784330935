
import React, { useState, useEffect } from 'react';
import { TextField, Button } from '@material-ui/core';
import axios from 'axios';
import { Box, } from "@mui/material";
import './Admin.css'
import CustomStyles from '../customComponent/DDownStyles';
import { ModelAdminSelectedValue, getSelectedvalue } from '../Redux/ApiCall/SelectedValueSlice';

import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';


const PowerAppForm = () => {

  const [formdata, setformData] = useState({ Column1: '', Column2: "", Column3: "", Column4: "", Column5: "", Column6: "", Column7: "", PowerAppsId: "" })
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [Success, setsuccess] = useState("");

  const Column1 = formdata.Column1
  const Column2 = formdata.Column2
  const Column3 = formdata.Column3
  const Column4 = formdata.Column4
  const Column5 = formdata.Column5
  const Column6 = formdata.Column6
  const Column7 = formdata.Column7
  const PowerAppsId = formdata.PowerAppsId


  const dispatch = useDispatch()

  useEffect(() => {
    dispatch((getSelectedvalue()))
  }, []);


  const { AdminSelectedValue, data } = useSelector((store) => store.selectedValue1)



  const customFilter = (option, searchText) => {
    if (
      option.data.Column4?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.Column1?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.Column2?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.Column3?.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };


  const update_id = AdminSelectedValue.id



  const url = process.env.REACT_APP_API_URL_DATA + `powerdata/${update_id}/`





  const handleChange = (event) => {
    setformData({ ...formdata, [event.target.name]: event.target.value });
  };


  const handleChange2 = (newValue) => {
    dispatch(ModelAdminSelectedValue(newValue))
    setformData(newValue)
  }




  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }
    try {
      await axios.put(url, { Column1, Column2, Column3, Column4, Column5, Column6, Column7, PowerAppsId }, config);
      setsuccess('Succesfully updated')
      setLoading(false);
      setformData("")
    } catch (error) {
      setLoading(false);
      setError(error.request.responseText)

    }
    setTimeout(() => {
      setError("")
      setsuccess("")
      setformData({
        Column1: "",
        Column2: "",
        Column3: "",
        Column4: "",
        Column5: "",
        Column6: "",
        Column7: "",
        PowerAppsId: "",
      })
    }, 5000)

  }

  return (
    <>
      <Box className='Box1'>
        <h1 className='headingStyle'>
          Power App Form
        </h1>
        {Success && <span style={{color:'white'}}>{Success}</span>}
            {error && <span style={{color:'white'}}>{error}</span>}

        <Select
          getOptionValue={(option) => `${option}`}
          isSearchable={true}
          filterOption={customFilter}
          className="selectStyle"
          styles={CustomStyles}
          options={data}
          value={AdminSelectedValue}
          onChange={handleChange2}
          getOptionLabel={(option) => option.Column4}
          placeholder={"Find Item"}
        />
      </Box>
      <Box className='Box2'>
        <form onSubmit={handleSubmit}>
          <TextField
            required
            className='field'
            name='Column1'
            id="Column1"
            label='Column1'
            variant='filled'
            value={formdata.Column1}
            onChange={handleChange}
          />
          <TextField
            required
            className='field'
            name='Column2'
            label='Column2'
            variant='filled'
            value={formdata.Column2}
            onChange={handleChange}
          />

          <TextField
            required
            className='field'
            label='Column3'
            name='Column3'
            variant='filled'
            value={formdata.Column3}
            onChange={handleChange}
          />
          <TextField
            required
            className='field'
            label='Column4'
            name='Column4'
            variant='filled'
            value={formdata.Column4}
            onChange={handleChange}
          />

          <TextField
            required
            className='field'
            label='Column5'
            name='Column5'
            variant='filled'
            value={formdata.Column5}
            onChange={handleChange}
          />

          <TextField
            required
            className='field'
            label='Column6'
            name='Column6'
            variant='filled'
            value={formdata.Column6}
            onChange={handleChange}
          />

          <TextField
            required
            className='field'
            label='Column7'
            name='Column7'
            variant='filled'
            value={formdata.Column7}
            onChange={handleChange}
          />
          <TextField
            required
            className='field'
            label='Power Id'
            name='PowerAppsId'
            id="name"
            hiddenLabel
            variant='filled'
            value={formdata.PowerAppsId}
            onChange={handleChange}
          />
          <br />
          <Button variant="contained" onClick={handleSubmit} color="primary" type="submit">
            Update
          </Button>
        </form>
      </Box>
    </>


  );
};

export default PowerAppForm;
