
import { Stack, Box } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomCheckBox from "../../../customComponent/CustomCheckBox";
import { ModelOilFreeMetric,ModelComponents, ModelFeatures } from "../../../Redux/Components/OFC/OFCSlice";

const OFCBox4=()=>{
    const{
        OilFreeMetric, Features, Components
    } = useSelector((state)=>state.OFC);
    const dispatch = useDispatch()


    return (
        <Box margin={'18% 0 0 3%'} sx={{display:{xs:'none', sm:'none', md:'block', lg:'block'}}} >
            <Stack direction={'row'} spacing={2}>
            <CustomCheckBox name={"Metric"}
                     checked={OilFreeMetric}
                    onChange= {event => dispatch(ModelOilFreeMetric(!OilFreeMetric))} />

                      <CustomCheckBox name={`Features`} 
                       checked={Features}
                        onChange= {event => dispatch(ModelFeatures(!Features))}
                    />
                     <CustomCheckBox name={`Components`} 
                       checked={Components}
                        onChange= {event => dispatch(ModelComponents(!Components))}
                    />
            </Stack>
        </Box>
    )
}
export default OFCBox4;