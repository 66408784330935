
import React from "react";
import "../../../../CSS/index.css";
import CustomSlider from "../../../../customComponent/Customslider";
import {Grid, Box } from "@mui/material";
import CustomCheckBox from "../../../../customComponent/CustomCheckBox";
import LgSrnHydroBox4 from "./lgSrnHydroBox4";
import { useSelector, useDispatch } from "react-redux";
import { SlideSlider6_20,SlideSlider6_21, SlideSlider6_18, SlideSlider6_19, ModelHPPMetric, SlideSlider7_3 } from "../../../../Redux/Components/HydroPlant/HydroPlantSlice";


const HydroBox4 = ({ label105_1, label53_1, label48_55, label60_3,label62_3,label105, label63_1, label48_58, label48_54, label48_57, label89, label100, label99 }) => {

    const { slider6_20, slider6_21, slider6_18, slider6_19 , Hppmetric, slider7_3} = useSelector((store)=>store.hydroplant);
    const dispatch = useDispatch()

    return (
        <>

            <Box  position='relative' sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }} >
                <Box display={label62_3 <= .67 ? 'block' : 'none'}>
                <h6 style={{ textAlign: 'left', marginTop: '0', fontWeight: '600' }}>
                Super Low Energy(SLE)</h6></Box>

                <Box display={label62_3 <= .74 ? 'block' : 'none'}>
                <h6 style={{ textAlign: 'left', marginTop: '0', fontWeight: '600' }}>
                Platinum</h6></Box>

                <Box display={label62_3 <= .79 ? 'block' : 'none'}>
                <h6 style={{ textAlign: 'left', marginTop: '0', fontWeight: '600', color:'yellow' }}>
                EnergyPLUS</h6></Box>

                <CustomCheckBox  name={"Metric"} checked={Hppmetric} onChange={event => dispatch(ModelHPPMetric(!Hppmetric))} />
                <Grid container style={{ textAlign: 'center' }}>
                    <Grid item xs={6} sm={6} md={6}>
                        <h1 className="headingStyle">Left side value</h1>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <CustomSlider
                                    SliderName={'Flow USGPM'}
                                    height={129}
                                    unitnameDisplay={Hppmetric === true? 'block' : 'none'}
                             unitvalueDisplay={Hppmetric === true? 'block' : 'none'}
                                    max={30}
                                    min={-20}
                                    value={slider6_20}
                                    onChange={e => dispatch(SlideSlider6_20(e.target.value))}
                                    unitname={'Flow L/S'}
                                    unitvalue={label48_54}
                                    BottomName={label105_1}
                                />
                            </Grid> <Grid item xs={6} sm={6} md={6} lg={6}>
                                <CustomSlider
                                    SliderName={'Head mts'}
                                    height={129}
                                    min={-20}
                                    max={30}
                                    value={slider6_21}
                                    onChange={e => dispatch(SlideSlider6_21(e.target.value))}
                                    unitname={'KPA'}
                                    unitvalue={label53_1}
                                    BottomName={label48_55}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <h1 className="headingStyle">Right side value</h1>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <CustomSlider
                                    SliderName={'Flow USGPM'}
                                    height={129}
                                    unitnameDisplay={Hppmetric === true? 'block' : 'none'}
                        unitvalueDisplay={Hppmetric === true? 'block' : 'none'}
                                    min={-20}
                                    max={30}
                                    value={slider6_18}
                                    onChange={e => dispatch(SlideSlider6_18(e.target.value))}
                                    unitname={'Flow L/S'}
                                    unitvalue={label48_57}
                                    BottomName={label105}
                                />
                            </Grid> <Grid item xs={6} sm={6} md={6} lg={6}>
                                <CustomSlider
                                    SliderName={'Head mts'}
                                    height={129}
                                    max={30}
                                    min={-10
                                    }
                                    value={slider6_19}
                                    onChange={e => dispatch(SlideSlider6_19(e.target.value))}
                                    unitname={'KPA'}
                                    unitvalue={label63_1}
                                    BottomName={label48_58}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <LgSrnHydroBox4
                label105_1={label105_1} label53_1={label53_1} label48_55={label48_55} label48_54={label48_54}
                label105={label105} label63_1={label63_1} label48_58={label48_58} label48_57={label48_57} label89={label89}
                label60_3={label60_3} label62_3={label62_3} label99 = {label99} label100 = {label100}

            />
        </>
    )
}
export default HydroBox4;