
import React from "react";
import "../../../CSS/index.css";
import Box3MidContent from "./Box3compt/Box3MidCompt";
import { styled , Grid, Box} from "@mui/material";
import Select from 'react-select';
import CustomStyles from "../../../customComponent/DDownStyles";
import CustomSlider1 from "../../../customComponent/CustomSlider1";
import LgScreenBox3 from "./LgScreenBox3";
import BoxField from "../Smallcomponents/BoxField";
import { useSelector, useDispatch } from "react-redux";
import { SlideSlider1, SlideSlider5_1, SlideSlider3_1 } from "../../../Redux/Components/Chiller/ChillerSlice";

const Box3 = (props) => {

    const {selectedValue2} = useSelector((store)=> store.selectedValue2)
    const {
        slider1,
        slider5_1,
        slider3_1,
        degradation2,
        Adj
    } = useSelector((store)=>store.chiller);

    const dispatch = useDispatch()

    const Box1Styled = styled(Box)(({ theme }) => ({
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '290px',
            marginLeft: '75px'
        }
    }));



    return (
        <>
            <Box sx={{ m: '2% 0 1% 3%' }}>
                <Grid container>
                    <Grid item xs={5} sm={5} md={5} lg={12}>
                        <Box width='100%'>
                            <Box1Styled>
                            <Select
                getOptionValue={(option) => `${option}`}
                isSearchable={true}
                filterOption={props.customFilter}
                className="selectStyle"
                styles={CustomStyles}
                options={props.options}
                value={selectedValue2}
                onChange={props.HandleChange}
                getOptionLabel={(option) => option.Column4}
                placeholder={"Find Item"}
              />
                            </Box1Styled>
                        </Box>
                    </Grid>
                    <Grid item xs={6} >
                        <Box width='100%' sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}>
                        <Select styles={CustomStyles}
                            className="selectStyle"
                                options={props.options}
                                value={selectedValue2}
                                onChange={props.HandleChange}
                                getOptionLabel={(option) => option.Column4}
                                placeholder={'Find Item'} />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container>

                    <Grid item xs={5} sm={3} lg={3} md={3}>
                    <BoxField
                    degradation ={false}
                        selectedvalue=  {selectedValue2.Column1}
                    /> 
                    </Grid>
                    <Grid item xs={6} sm={4} lg={3} md={4}>
                    <BoxField
                       degradation ={degradation2}
                        selectedvalue =  {selectedValue2.Column3}
                    />
                    </Grid>
                    <Grid item sm={4} lg={4} md={4}>
                        <Box width='100%' sx={{ display: { xs: 'none', sm: 'block', lg: 'block', md: 'block' } }}>
                            <Select styles={CustomStyles}
                            className="selectStyle"
                                options={props.options}
                                value={selectedValue2}
                                onChange={props.HandleChange}
                                getOptionLabel={(option) => option.Column4}
                                placeholder={'Find Item'} />
                        </Box>
                    </Grid>
                </Grid>
                <Box margin="6px 12px 6px 12px"
                    sx={{ display: { xs: "block", sm: "none", md: "none", lg: "none" } }}>
                    <CustomSlider1
                        display={Adj}
                        size={'small'}
                        orientation={'horizontal'}
                        SliderName={'Capacity Adj'}
                        width={'90%'}
                        value={slider1}
                        min={-500}
                        max={500}
                        onChange={e => dispatch(SlideSlider1(e.target.value))}
                    />
                    <Box3MidContent
                        label16_2={props.label16_2}
                        label16_3={props.label16_3}
                    />
                    <CustomSlider1
                        SliderName={'Eff Adj'}
                        orientation={'horizontal'}
                        size={'small'}
                        display={Adj}
                        width={'90%'}
                        value={slider5_1}
                        min={-500}
                        max={500}
                        onChange={event => dispatch(SlideSlider5_1(event.target.value))} />
                    <CustomSlider1
                        display={true}
                        width={'90%'}
                        value={slider3_1}
                        min={-200}
                        max={100}
                        onChange={e => dispatch(SlideSlider3_1(e.target.value))}
                    />
                    <Grid container style={{ textAlign: 'center' }} >
                        <Grid item md={4} xs={4} sm={4} lg={4} >
                            <h5>$ Per/Kwr</h5>
                            <h6>{props.label17_4 ? props.label17_4 : 0}</h6>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4} lg={4} >
                            <h5>Chiller $</h5>
                            <h6>{props.label30_5 ? props.label30_5 : 0}</h6>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4} lg={4} >
                            <h5>$ Per/Ton</h5>
                           <h6>{props.label17_5 ? props.label17_5 : 0}</h6>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: '100%', display: { xs: "none", sm: "block", md: "block", lg: "block" } }} >
                    <LgScreenBox3
                        label16_2={props.label16_2}
                        label16_3={props.label16_3}
                        label17_4={props.label17_4}
                        label17_5={props.label17_5}
                        label30_5={props.label30_5}
                    />
                </Box>

            </Box>
        </>
    )
}

export default Box3;

