
import React, { useContext } from "react";
import AppBar from "../../AppBar/AppBar";
import {  Box, Stack, Drawer   } from "@mui/material";
import CustomCheckBox from "../../customComponent/CustomCheckBox";
import { Context } from "../../App";
import "../../CSS/Home.css";
import "../../CSS/ValuePage.css";
import ValueContent from "./ValueContent";
import { useDispatch, useSelector } from "react-redux";
import { ModelDrawers, ModelDTC, ModelECWT, ModelGlobalPower, ModelIPLVAir, ModelIPLVWater, ModelIPLVWT, ModelLCHWAir, ModelLCHWWater, ModelOilDegradation, ModelOilDegradationVideo, ModelPerformance, ModelPlantEff, ModelUSDKWR, ModelUSDTon } from "../../Redux/Components/ValueTool/ValueToolSlice";

const Valuepage = () => {
    
    const { Drawers,
        OilDegradation,
        OilDegradationVideo,
        GlobalPower,
        IPLVWater,
        IPLVAir,
        ECWT,
        LCHWWater,
        LCHWAir,
        PlantEff,
        IPLVWT,
        USDKWR,
        USDTon,
        Performance,
        DTC } = useSelector((store)=> store.valueTool);

        const dispatch = useDispatch()

    return (
        <>


            <AppBar headline={
                <h1 className="headingStyle" style={{textAlign:'center'}}>
Value Proposition
                </h1>
            } />
            
            <Box className="Value-Page">

          
            <Drawer anchor="left" open={Drawers} onClose={e =>
                dispatch(ModelDrawers(!Drawers))} >
                <Box p={2} paddingBottom={20} role='presentation' bgcolor={'#B0000f'} sx={{width:{xs:'250px', sm:'250px',
                md:'400px', lg:'400px'}}}>
                <Stack direction={'column'} spacing={2}>

                
                 <CustomCheckBox
                 style={{fontSize:'15px', marginLeft:'2px'}}
                 name={"Oil Degradation"} 
                        checked={OilDegradation}
                        onChange={
                            e => dispatch(ModelOilDegradation(!OilDegradation))
                        }
                    />
                    <CustomCheckBox
                 style={{fontSize:'15px'}}
                 name={"Oil Degradation Video"} 
                        checked={OilDegradationVideo}
                        onChange={
                            e => dispatch(ModelOilDegradationVideo(!OilDegradationVideo))
                        }
                    />
            
                    <CustomCheckBox
                 style={{fontSize:'15px'}}
                 name={"Global Power Price per Kw"} 
                        checked={GlobalPower}
                        onChange={
                            e => dispatch(ModelGlobalPower(!GlobalPower))
                        }
                    />
                    <CustomCheckBox
                 style={{fontSize:'15px'}}
                 name={"IPLV Water-cooled"} 
                        checked={IPLVWater}
                        onChange={
                            e => dispatch(ModelIPLVWater(!IPLVWater))
                        }
                    />
                    <CustomCheckBox
                 style={{fontSize:'15px'}}
                 name={"IPLV Air-cooled"} 
                        checked={IPLVAir}
                        onChange={
                            e => dispatch(ModelIPLVAir(!IPLVAir))
                        }
                    />
                    <CustomCheckBox
                 style={{fontSize:'15px'}}
                 name={"Impact on ECWT on COP"} 
                        checked={ECWT}
                        onChange={
                            e => dispatch(ModelECWT(!ECWT))
                        }
                    />
                    <CustomCheckBox
                 style={{fontSize:'15px'}}
                 name={"High LCHW water cooled"} 
                        checked={LCHWWater}
                        onChange={
                            e => dispatch(ModelLCHWWater(!LCHWWater))
                        }
                    />
                    <CustomCheckBox
                 style={{fontSize:'15px'}}
                 name={"High LCHW air cooled"} 
                        checked={LCHWAir}
                        onChange={
                            e => dispatch(ModelLCHWAir(!LCHWAir))
                        }
                    />
                    <CustomCheckBox
                 style={{fontSize:'15px'}}
                 name={"Plant Efficiency"} 
                        checked={PlantEff}
                        onChange={
                            e => dispatch(ModelPlantEff(!PlantEff))
                        }
                    />
                    <CustomCheckBox
                 style={{fontSize:'15px'}}
                 name={"IPLV Weighting"} 
                        checked={IPLVWT}
                        onChange={
                            e => dispatch(ModelIPLVWT(!IPLVWT))
                        }
                    />
                    <CustomCheckBox
                 style={{fontSize:'15px'}}
                 name={"USD $/Kwr"} 
                        checked={USDKWR}
                        onChange={
                            e => dispatch(ModelUSDKWR(!USDKWR))
                        }
                    />
                  <CustomCheckBox
                 style={{fontSize:'15px'}}
                 name={"USD $/ton"} 
                        checked={USDTon}
                        onChange={
                            e => dispatch(ModelUSDTon(!USDTon))
                        }
                    />
                    <CustomCheckBox
                 style={{fontSize:'15px'}}
                 name={"Performance 12c LCHW"} 
                        checked={Performance}
                        onChange={
                            e => dispatch(ModelPerformance(!Performance))
                        }
                    />
                    <CustomCheckBox
                 style={{fontSize:'15px'}}
                 name={"DTC History"} 
                        checked={DTC}
                        onChange={
                            e => dispatch(ModelDTC(!DTC))
                        }
                    />
                    </Stack>

                </Box>
            </Drawer>
            <ValueContent/>
            </Box>
        </>
    )
}


export default Valuepage;