import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    currencydata:[],
    selectedItems:{
        Column1: "United State Dollar",
        Column2: "Default (USD)",
        Column3: 1,
        PowerAppsId: "XYTo8lk0ab"
      },
      AdminselectedItems:{
        Column1: "United State Dollar",
        Column2: "Default (USD)",
        Column3: 1,
        PowerAppsId: "XYTo8lk0ab"
      },
    InputValue3:''
}


const url = process.env.REACT_APP_API_URL_DATA + "currencydata/"



export const getCurrency= createAsyncThunk('Chiller/getCurrency', async (object, { getState, rejectWithValue }) => {
   
      const { data } = await axios.get(
        url, {
            headers: {
            "Content-Type": "application/json",
              "Authorization": "Bearer " + localStorage.getItem('authToken')
            }
          }
      );
      return data;
     

    } 
)





const selectCurrency = createSlice({
    name:'currency',
    initialState,
    reducers:{
        ModelSelectedItems:(state, action)=> {
            state.selectedItems = action.payload
        } ,
        ModelAdminSelectedItems:(state, action)=> {
            state.AdminselectedItems = action.payload
        } ,
        ModelInputValue3:(state, action)=>{
            state.InputValue3 = action.payload
        }
    },
    extraReducers:(builder) => {
        builder.addCase(getCurrency.fulfilled, (state, { payload }) => {
            state.currencydata = payload
            state.isloading = true
        })}
},)

export const {ModelSelectedItems, ModelInputValue3, ModelAdminSelectedItems} = selectCurrency.actions

export default selectCurrency.reducer