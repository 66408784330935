import React from "react";
import {  Box,  IconButton } from "@mui/material";
import ListIcon from '@mui/icons-material/List';
import "./../../CSS/ValuePage.css";
import "../../CSS/index.css";
import SmallContent from "./SmallContent";

import USDperKw from "./USDperKw";
import { useDispatch, useSelector } from "react-redux";
import { ModelDrawers } from "../../Redux/Components/ValueTool/ValueToolSlice";


const ValueContent = () => {


    const { Drawers,
        OilDegradation,
        OilDegradationVideo,
        GlobalPower,
        IPLVWater,
        IPLVAir,
        ECWT,
        LCHWWater,
        LCHWAir,
        PlantEff,
        IPLVWT,
        USDKWR,
        USDTon,
        Performance,
        DTC
     } = useSelector((store)=> store.valueTool);
     const dispatch = useDispatch()
  return (
    <>
        <Box sx={{ height: '100vh' }} padding={2}>
                <IconButton >
                    <ListIcon 
                     edge='start'
                    style={{fontSize:'40px'}}
                        color="primary"
                        aria-label='logo'
                        onClick={e =>
                            dispatch(ModelDrawers(!Drawers))}
                    />
                    <h1 className="headingStyle"> Click on Icon</h1>
                </IconButton>
                <Box  position='relative' sx={{width:{xs:'95%', sm:'90%', md:'80%', lg:'70%'}}}>
               
                <SmallContent value={OilDegradation}
                    context={
                        <img  width={'100%'} src="../images/OilDeg.png" alt="WATER COOLED" />
                    }
                />
                <SmallContent value={OilDegradationVideo}
                    context={
                    <video autoPlay={false} loop={false} controls={true}  >
                        <source
                            src="https://res.cloudinary.com/dk3mlofmi/video/upload/v1655730715/turbo_wcRN35UL_uk0mbx.mp4"
                            type="video/mp4"
                        />
                    </video>
                    }
                />
                   <SmallContent value={GlobalPower}
                    context={
                        <USDperKw/>
                    }
                />
                
            
                <SmallContent value={IPLVWater}
                    context={
                        <img width={'100%'} src="../images/IPLVWater.png" alt="WATER COOLED" />
                    }
                />
                                <SmallContent value={IPLVAir}
                    context={
                        <img width={'100%'} src="../images/IPLVAir.png" alt="AIR COOLED" />
                    }
                />
                                <SmallContent value={ECWT}
                    context={
                        <img width={'100%'} src="../images/ECWTCop.png" alt="ECTW COP" />
                    }
                />
                <SmallContent value={LCHWAir}
                    context={
                        <img width={'100%'} src="../images/PerformenceAC2.png" alt="LCHW Air" />
                    }
                />
                  <SmallContent value={LCHWWater}
                    context={
                        <img width={'100%'} src="../images/PerformenceWC2.png" alt="LCHW Water" />
                    }
                />
                <SmallContent value={PlantEff}
                    context={
                        <img width={'100%'} src="../images/PlantEff.png" alt="Plant EFF" />
                    }
                />
                
                <SmallContent value={USDKWR}
                    context={
                        <img width={'100%'} src="../images/USDperKW.png" alt="USD KWR" />
                    }
                />
                <SmallContent value={USDTon}
                    context={
                        <img width={'100%'} src="../images/USDperTon.png" alt="USD TON" />
                    }
                />
                  <SmallContent value={Performance}
                    context={
                        <img width={'100%'} src="../images/COVSOF.png" alt="Performance" />
                    }
                />
                     
                <SmallContent value={IPLVWT}
                    context={
                        <img width={'100%'} src="../images/IPLVWT.png" alt="IPLVWT" />
                    }
                />
                   <SmallContent value={DTC}
                    context={
                        <img width={'100%'} src="../images/History.png" alt="DTC" />
                    }
                />
               
                </Box>
            </Box>
    </>
    )
}

export default ValueContent