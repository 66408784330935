import React from "react";
import CustomHorizontalSlider from "../../../customComponent/CstmHozSlider";
import "./ToolBox3.css";
import "../../../CSS/index.css";
import { Box, Typography } from "@mui/material";
import LgScrnToolBox3 from "./LgScrnToolBox3";
import { useDispatch, useSelector } from "react-redux";
import {  SlideSlider6_13, SlideSlider6_12 } from "../../../Redux/Components/LowLift/LowLiftSlice";

const ToolBox3 = (props) => {

    const { slider6_12, slider6_13 , LowLiftApp,  LowLiftMetric } = useSelector((state)=>state.LowLift);
    const dispatch = useDispatch()

    return (
        <>
            <Box sx={{display:{xs:'block', sm:'block',md:'none',lg:'none'}}}>
                <Box margin={'0 3%'} >
                    <CustomHorizontalSlider
                        SliderName={'Low Lift'}
                        display={LowLiftApp}
                        width={'90%'}
                        value={slider6_13} min={13} max={35}
                        onChange={
                            e => dispatch(SlideSlider6_13(e.target.value))
                        }
                        unitvalue={<img src="../images/cloud.jpeg" width='22%' alt="Condenser Water" />}
                    />
                    <CustomHorizontalSlider
                        SliderName={'High SST'}
                        display={LowLiftApp}
                        width={'90%'}
                        value={slider6_12} min={8} max={26}
                        onChange={
                            e => dispatch(SlideSlider6_12(e.target.value))
                        }
                    />
                </Box>
                <div width='100%' height='100%' position={'relative'}>
                    <img src="../images/power.png" alt="Plant1" className="HydroPlant3" />
                    <div className="EWT"><Typography variant="h6" style={{ fontSize: '13px', textAlign: 'center', color: 'white' }}> EWT {LowLiftMetric === true ? props.label64_4 +' °F' : props.label90+' °C' } </Typography></div>
                    <div className="LWT"><Typography variant="h6" style={{ fontSize: '13px', textAlign: 'center', color: 'white' }}> LWT {LowLiftMetric === true ? props.label68+' °F' : props.label90_2+' °C'} </Typography></div>
                    <div className="EWT1"><Typography variant="h6" style={{ fontSize: '13px', textAlign: 'center', color: 'white' }}> EWT {LowLiftMetric === true ? props.label64_5+' °F' :props.label90_1+' °C'} </Typography></div>
                    <div className="LWT1"><Typography variant="h6" style={{ fontSize: '13px', textAlign: 'center', color: 'white' }}> LWT {LowLiftMetric === true ? props.label64_6+' °F' : props.label90_3+' °C'} </Typography></div>
                    <Box className="RequiredPopup">
                        <Box  display={props.label79 < 13 ? 'block' : 'none'}>
                            <h6 style={{textAlign:'left'}}>
                            Low Lift Required
                            </h6>
                        </Box>
                       

                        <Box display={props.label79 < 13 ? 'block' : 'none'}>
                        <img className="RequiredLowLift" src="../images/lowlift.png" alt='Images on Lowlift'/>
                        </Box>
                        <Box display={slider6_12 > 19 ? 'block' : 'none'}>
                            <h6 style={{textAlign:'left' , marginLeft:'-10%'}}>
                            High SST Compressor {<br/>} Version Recommended
                            </h6>
                        </Box>
                    </Box>
                </div>
            </Box>
               <LgScrnToolBox3
                     label90={props.label90}
                    label90_2={props.label90_2}
                    label90_1={props.label90_1}
                    label90_3={props.label90_3}
                    label79={props.label79}
                    label64_4 ={props.label64_4}
                    label68= {props.label68}
                    label64_6 ={props.label64_6}
                    label64_5 = {props.label64_5}
               />
          
        </>
    )
}
export default ToolBox3;