import React, { useContext } from "react";
import { Box, Grid } from '@mui/material'
import Box4FirstContainer from "./Box4compt/Box4FirstContainer";
import Box4SecondContainer from "./Box4compt/Box4SecondContainer";
import Box4ThirdContainer from "./Box4compt/Box4ThirdContainer";
import CustomSlider1 from "../../../customComponent/CustomSlider1";
import CustomCheckBox from "../../../customComponent/CustomCheckBox";
import { useDispatch, useSelector } from "react-redux";
import { ModelDegradation2, SlideSlider2_3 } from "../../../Redux/Components/Chiller/ChillerSlice";
import "../../../CSS/index.css"

const Box4 = (props) => {
    const {
        slider2_3,
        degradation2
    } = useSelector((store)=> store.chiller);


    const { selectedValue2} = useSelector((store) => store.selectedValue2)
    const dispatch = useDispatch()
    
    
    return (
        <>
        
                <Box sx={{ m: '6% 0 0 2%', textAlign: 'center', display:{xs:"block",sm:"block", md:"none", lg:"none"} }}>
                    <Grid container>
                        <Grid item xs={4} sm={4}>
                            <Box sx={{ width: '100%' }} display={degradation2 === true ? 'block' : 'none'} >
                                <h6>New Opex With Oil Degradation $</h6>
                                <h6>{props.label38_1 ? props.label38_1 : 0}</h6>
                            </Box>

                        </Grid>
                        <Grid item xs={3} sm={3} >
                            <h6>Opex Per Annum $</h6>
                            <h6>{props.label30_3 ? props.label30_3 : 0}</h6>
                        </Grid>
                        <Grid item xs={4} sm={4} >
                            <h6>Total Cost of OwnerShip 10 years$</h6>
                            <h6>{props.label30_4 ? props.label30_4 : 0}</h6>
                        </Grid>
                        <Grid item xs={7} sm={7} >
                            <CustomSlider1 width={'80%'}
                                display={degradation2}
                                min={0} max={20}
                                value={slider2_3}
                                onChange={e => dispatch(SlideSlider2_3(e.target.value))}

                            />
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Box display={ selectedValue2.Column3 === "Centrifugal Oil Free" ? "none" : 'block'}>
                            <CustomCheckBox name={'Add Oil Degradation'}
                            checked={degradation2}
                                onChange={event => dispatch(ModelDegradation2(!degradation2))} /> 
                                </Box>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <Box sx={{ width: '100%' }} display={degradation2 === true ? 'block' : 'none'}>
                                <h5>{props.label26_2}</h5>
                                <h5>Oil Degradation%</h5>
                            </Box>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Box sx={{ width: '100%' }} display={degradation2 === true ? 'block' : 'none'}>
                                <h5>{props.label32_1}</h5>
                                <h5>Years of Operation</h5>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            <Box width='100%' height='100%' sx= {{display: {xs:'none', sm:'none', md:'block', lg:'block'}}}>  
            <Grid container>
                <Grid item md={8} xs={8} sm={8} lg={8} style={{ marginTop: 30 }}>
                    <Box4FirstContainer
                        label26_2={props.label26_2}
                        label32_1={props.label32_1}
                        label38_1={props.label38_1}
                        label30_3={props.label30_3}
                        label30_4={props.label30_4}
                    />
                </Grid>
                <Grid item md={3} xs={3} sm={3} lg={3} style={{ marginTop: 30 }}>
                    <Box4SecondContainer
                        label35={props.label35}
                    />
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12} style={{ marginTop: 30 }}>
                    <Box4ThirdContainer />
                </Grid>
             </Grid>
            </Box>
        </>
    )
}
export default Box4;