
import React from "react";
import { Context } from "../../../App";
import { Grid } from "@mui/material";
import Box3ThirdCompt from "./Box3compt/Box3Third";
import Box3MidContent from "./Box3compt/Box3MidCompt";
import CustomSlider1 from "../../../customComponent/CustomSlider1";
import { useDispatch, useSelector } from "react-redux";
import { SlideSlider1, SlideSlider5_1 } from "../../../Redux/Components/Chiller/ChillerSlice";

const LgScreenBox3 = (props) => {

    const {
        slider1,
    slider5_1,
        Adj
    } = useSelector((store)=> store.chiller);

    const dispatch = useDispatch()
    return (

            <Grid container>
                <Grid item sm={1} lg={1} md={1} >
                    <CustomSlider1
                        m={'10px 0 0 0'}
                        display={Adj}
                        orientation={'vertical'}
                        size={'small'}
                        color={'primary'}
                        SliderName={'Capacity Adj'}
                        width={58}
                        height={232}
                        value={slider1}
                        min={-500}
                        max={500}
                        onChange={e => dispatch(SlideSlider1(e.target.value))}
                    />

                </Grid>
                <Grid item lg={6} md={7} sm={6}>
                    <Box3MidContent
                        label16_2={props.label16_2}
                        label16_3={props.label16_3}
                    />
                </Grid>
                <Grid item sm={1} lg={1} md={1} >
                    <CustomSlider1
                        topicMargin={'0 0 0 10px'}
                        m={'10px 0 0 0'}
                        display={Adj}
                        orientation={'vertical'}
                        SliderName={'Eff Adj'}
                        height={246}
                        width={67}
                        size={'small'}
                        value={slider5_1}
                        min={-500}
                        max={500}
                        onChange={e => dispatch(SlideSlider5_1(e.target.value))}
                    />
                </Grid>
                <Grid item  sm={3} lg={3} md={3}>
             <Box3ThirdCompt
                 label17_4={props.label17_4}
                 label17_5={props.label17_5}
                 label30_5={props.label30_5}             />
      </Grid> 
                
            </Grid>
    )
}

export default LgScreenBox3;


 
    