import React from "react";
import { Box, Slider, Grid, styled } from "@mui/material";
import "../CSS/index.css";


const WhiteSlider = styled(Slider)(({ theme }) => ({
    "& .MuiSlider-markLabel": {
      color: "white"
    },
    "& .MuiSlider-mark": {
      backgroundColor: "white"
    }
  }));
const CustomHorizontalSlider = (props) => {
    return <>
        <Box width={props.width2} height={props.height2} display={props.display === true ? 'block' : 'none'} className={props.className} >
            <h1 className="topicTypo">{props.SliderName || null}</h1>
            <Box height={props.height} width={props.width} textAlign={props.textAlign}>
                <WhiteSlider
                m={props.m}
                marks={props.marks}
                    color={props.color}
                    size={props.size}
                    orientation={props.orientation}
                    value={props.value}
                    min={props.min}
                    max={props.max}

                    onChange={props.onChange}
                    valueLabelDisplay="auto"
                />
            </Box>
            <Grid container>
                <Grid item xs={6} sm={6}>
                    <h6>{props.BottomValue || null}</h6>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <h6>{props.unitvalue || null}</h6>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <h6>{props.BottomName || null}</h6>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <h6>{props.unitname || null}</h6>
                </Grid>
            </Grid>


        </Box>
    </>
}


export default CustomHorizontalSlider;