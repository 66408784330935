
import MidContent from "./Box1compt/Box1MidCompt";
import { Box, Grid } from "@mui/material";
import Box1ThirdCompt from "./Box1compt/Third";
import CustomSlider1 from "../../../customComponent/CustomSlider1";
import { useDispatch, useSelector } from "react-redux";
import { SliderCapacity, SlideEffAdj } from "../../../Redux/Components/Chiller/ChillerSlice";


const LgScreen = (props) => {

  const {
    capacity,
    EffAdj,
    Adj,
  } = useSelector((store)=>store.chiller);

  const dispatch = useDispatch()


  return (
    <Box sx={{ width: '100%', margin: "6px 0 6px 12px", 
    display:{sm:"block",md:'block', lg:'block', xs:'none'} }}>
    
      <Grid container>
        <Grid item md={1} lg={1} sm={1} >
          <CustomSlider1
            m={'10px 0 0 0'}
            display={Adj}
            orientation={"vertical"}
            size={'small'}
            color={'primary'}
            SliderName={'Capacity Adj'}
            width={58}
            height={232}
            value={capacity}
            min={-500}
            max={500}
            onChange={e => dispatch(SliderCapacity(e.target.value))}
          />
        </Grid>
        <Grid item lg={6} md={7} sm={6}>
          <MidContent
            label16={props.label16}
            label16_1={props.label16_1}
            label14={props.label14}
            label14_2={props.label14_2}
            label14_1={props.label14_1}
            label12={props.label12}
            label15_1={props.label15_1}
            label15={props.label15}
          />
        </Grid>
        <Grid item lg={1} md={1} sm={1} >
          <CustomSlider1
          color={'primary'}
            topicMargin={'0 0 0 10px'}
            m={'10px 0 0 0'}
            orientation={"vertical"}
            display={Adj}
            SliderName={'Eff Adj'}
            height={246}
            width={67}
            size={'small'}
            value={EffAdj}
            min={-500}
            max={500}
            onChange={event => dispatch(SlideEffAdj(event.target.value))}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={3}>
          <Box1ThirdCompt
            label17_1={props.label17_1}
            label17_2={props.label17_2}
            label30_2={props.label30_2}
          />
        </Grid>

      </Grid>
    </Box>
  )
}

export default LgScreen