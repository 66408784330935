import React from "react";
import { Grid } from "@mui/material";
import "../../../CSS/index.css";

const SmallBox2= (props) => {
    return (
        <>
            <Grid container>
            <Grid item md={7} sm={7} lg={7} style={{textAlign:'end'}}>
                <h5>{props.Valuename}</h5>
           </Grid>
           <Grid item md ={2} sm ={2} lg={2} style={{textAlign:'center'}}>
            <h5>{props.dollar}</h5>
           </Grid>
            <Grid item md={3} sm={3} lg={3}>
                <h5>{props.Value}</h5>
            </Grid>
            </Grid>

        </>
    )
}

export default SmallBox2;