import React, { useState } from "react";
import {
    Dialog,
    Button,
    Box,
    DialogTitle,
    TextField,
    Stack,
} from "@mui/material";
import { StyledButton } from "../../customComponent/StyledButton";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';







const ProfilePage = (props) => {


    const [first_name, setFname] = useState("");
    const [last_name, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [Success, setsuccess] = useState("");

    const handleSubmit =async(e)=>{
        e.preventDefault();
        const token = localStorage.getItem('authToken')

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }
        try {
            const {data} =  await axios.patch(process.env.REACT_APP_API_URL_USER +`me/update_profile/`, {first_name, last_name, email} ,config);
            setsuccess(data.message)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error.request.responseText)

        }
        setTimeout(() => {
            setError("")
            setsuccess("")
        }, 5000)
       
    }
    return (
        <>
             <Dialog open={props.openDialog} onClose={props.handlebuttonClose}>
            <DialogTitle>Change your Account Information</DialogTitle>
            {Success && <span>{Success}</span>}
            {error && <span>{error}</span>}
            <Box className="Box1" >
                <form onSubmit={handleSubmit}>
                    <Stack direction={"row"} gap={2}>
                        <TextField
                            required
                            className="field"
                            label="First_name"
                            name="first_name"
                            variant="filled"
                            value={first_name}
                            onChange={(e) => setFname(e.target.value)}
                        />
                        <TextField
                            required
                            className="field"
                            label="Last_name"
                            name="Column4"
                            variant="filled"
                            value={last_name}
                            onChange={(e) => setLname(e.target.value)}
                        />
                    </Stack>

                    <br />

                    <TextField
                        required
                        fullWidth
                        className="field"
                        label="Email"
                        name="Email"
                        type={"email"}
                        variant="filled"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <br />
                    <Stack direction={'row'} gap={3} style={{ margin: '25px' }}>
                        <Button
                        onClick={handleSubmit}
                            variant="filled"
                            style={{ color: "white", backgroundColor: "blue" }}
                            type="submit">
                            Update
                        </Button>
                        <StyledButton
                            variant="filled"
                            style={{ color: "white", backgroundColor: "red" }}
                            onClick={props.handlebuttonClose}>
                            Cancel
                        </StyledButton>
                    </Stack>
                </form>
               
            </Box>
        
        
            </Dialog>
    </>
  );

};
export default ProfilePage;
