
import React from 'react';
import "./../../CSS/Home.css";


import { Box, Stack, Divider } from '@mui/material';
import "../../CSS/index.css";
import { StyledButton } from '../../customComponent/StyledButton';

import {  Grid} from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom';


const LandingScreen = () => {
  return (
    <>
    <Box bgcolor='#B0000F' sx={{  pt:1.5}} className='main'  >
    <Grid container >
        <Grid item xs={5} sm={5} md={5} lg={5}>
            <Grid container>
                <Grid item xs={7} sm={7} md={4} lg={4}>
                    <Box display='flex' height='100%' width='100%'>
                        <img src="../images/danfoss.png" alt="Danfoss"  width="100%" />
                    </Box>
                    
                </Grid>
                <Grid item xs={5} sm={5} md={2} lg={2}>
                    <Box sx={{ height: '100%', width: '100%' }}>
                        <h4>ENGINEERING</h4>
                        <h4>TOMORROW</h4>
                    </Box>

                </Grid>
            </Grid>

        </Grid>
        <Grid item xs={1} sm={1} md={4} lg={4}>
            <Box display={{xs:'none', sm:'none', md:'block', lg:'block'}}>
          <a href="https://www.danfoss.com/en-au/" style={{ textDecoration: 'none' }}>  <h1 className='headingStyle' style={{paddingTop:'10px',  paddingRight:'25px',textAlign:'right'}}>About Us</h1> </a>
            </Box>
        </Grid>
        <Grid item xs={4} sm={4} md={3} lg={3}>
            <Stack direction={'row'} gap={2} pt='3%' pl={'4%'} divider={<Divider orientation="vertical" sx={{bgcolor:'white'}} flexItem />}>
            <Link to="/login" style={{ textDecoration: 'none' }} > <StyledButton sx={{outline:'1px solid white' , borderRadius:'20px'}}>Login</StyledButton> </Link>
           <Link to="/register" style={{ textDecoration: 'none' }} >      <StyledButton sx={{outline:'1px solid white' , borderRadius:'20px'}}>Register</StyledButton></Link>
            </Stack>
        </Grid>
    </Grid>
    <Box >
        <Grid container>
            <Grid xs={12} sm={12} md={6} lg={6} className='Container1'>
                <Stack direction={'column'} gap={3}>
                <h1 className='Heading'>Welcome to Danfoss Tool</h1>
                <h3 className='Content2'>
                Danfoss offers a broad range of products, including refrigeration and air conditioning equipment, heating controls, hydraulics, water controls, and drives for electric motors. The company also provides solutions for energy-efficient buildings, renewable energy systems, and industrial automation. 
                </h3>
                <Link  to="/login" style={{ textDecoration: 'none' }} >
                <StyledButton style={{width:'180px', borderRadius:'20px', marginLeft:'9%'}}> Explore</StyledButton> </Link>
                </Stack>
            </Grid>
            <Grid xs={0} sm={0} md={6} lg={6}>
                <Box className='Container2' display={{xs:'none', sm:'none', md:'block', lg:'block', xl:'block'}}>
                    <video autoPlay={false} controls={true}  >
                    <source
                        src="https://res.cloudinary.com/dk3mlofmi/video/upload/v1655730282/Danfoss_hnbqi5.mp4"
                        type="video/mp4"
                    />
                </video>
                </Box>
            </Grid>
           
        </Grid>
        <Box maxWidth={true} className='BottomContainer'>
            <h1 className='headingStyle' style={{textAlign:'left'}}>Follow us on</h1>
            <Stack direction={'row'} gap={3} >
                <a href='https://www.facebook.com/danfoss'>
           <StyledButton>
            <FacebookIcon style={{fontSize:'30px'}}/>
            </StyledButton>
            </a>
            <a href='https://www.youtube.com/user/DanfossGroup'>
            <StyledButton>
            <YouTubeIcon  className='icon2'/>
            </StyledButton>
            </a>
            <a href='https://twitter.com/danfoss'>
            <StyledButton>
                <TwitterIcon className='icon2'/>
            </StyledButton>
            </a>
            <a href='https://www.instagram.com/danfoss_group/?hl=en'>
            <StyledButton>
                <InstagramIcon className='icon2'/>
            </StyledButton>
            </a>
        </Stack>
        </Box>
        

        
    </Box>
</Box>
</>
  )
}

export default LandingScreen