import React from 'react';
import { Grid, Box, TextField, Typography, FormControlLabel, Checkbox, Container, Button, Avatar, Link as Linked } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { CssBaseline } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import "../../CSS/Home.css";
import AppBar from '../../AppBar/AppBar';



function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Linked color="inherit" href=" https://www.danfoss.com/en-au/">
                Danfoss
            </Linked>{'   '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}



const Log = () => {


    const [email1, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState("");
    const [Remember, setRemember] = useState(false);
    const [loading, setLoading] = useState(false);


    const history = useNavigate();
    const email = email1.toLowerCase()

    const handleSubmit = async (event) => {
        setLoading(true);

        event.preventDefault();
        const config = {

            Headers: {
                "Content-Type": "application/json",

            }
        }
        try {
            const { data } = await axios.post( process.env.REACT_APP_API_URL_USER + "token/", { password, email }, config,);
            localStorage.setItem("authToken", data.access);
            history("/home");
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error)
            setError(error.response.data.detail + ': Please Check your detail');
            setTimeout(() => {
                setError("")
            }, 5000)
        }

    };


    return (
        <>
                <AppBar headline={<h1 className='headingStyle'>Login Page</h1>} />
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'center', alignItems: 'center' }}>
                                <Grid container justifyContent='center'>
                                    <img src='/images/Icon.png' alt='Icon' width='200px' />
                                </Grid>
                                <Typography component="h1" variant="h6" pt='30px'>
                                    Welcome Back </Typography>
                                {error && <span>{error}</span>}
                                <Box component='form' onSubmit={handleSubmit} sx={{ pt: 1, pr: 5, pl: 6, pb: 6 }}>
                                    <TextField color='secondary' margin='normal' fullWidth required id='email'
                                        label='Email Address' name='email' autoFocus variant='outlined' type="email" value={email1} onChange={e => setEmail(e.target.value)} />
                                    <TextField color='secondary'
                                        margin="normal"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password" />
                                    <FormControlLabel
                                        control={<Checkbox checked={Remember} onChange={e => setRemember(!Remember)} color='secondary' value="remember" />}
                                        label="Remember me"
                                    />
                                    <LoadingButton
                                        type='submit'
                                        sx={{ mt: 3, mb: 2 }}
                                        color='tertiary'
                                        fullWidth
                                        size="small"
                                        onClick={handleSubmit}
                                        loading={loading}
                                        variant="contained"
                                    >
                                        <Typography color='primary' >Sign In</Typography>
                                    </LoadingButton>

                                    <Grid container>
                                        <Grid item xs>
                                            <Link to="/forgotpassword" variant="body2">
                                                Forgot password?
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Link to="/register" variant="body2">
                                                {"Don't have an account? Sign Up"}
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    <Copyright sx={{ mt: 5 }} />
                                </Box>
                            </Box>




                        </Box>
                    </Container>
            

        </>
    )
}

export default Log