import { Checkbox, Grid,  FormControlLabel, Box } from "@material-ui/core";
import React, { useContext } from "react";

import "../../../../CSS/index.css"
import CustomSlider from "../../../../customComponent/Customslider";
import { Stack } from "@mui/material";
import CustomCheckBox from "../../../../customComponent/CustomCheckBox";
import CheckBoxStyled from "../../../../customComponent/CheckboxStyle";


import { useSelector, useDispatch } from "react-redux";
import { SlideSlider6_20,SlideSlider6_21, SlideSlider6_18, SlideSlider6_19, ModelHPPMetric, ModelVSDPUMP , ModelPIBCV} from "../../../../Redux/Components/HydroPlant/HydroPlantSlice";


const LgSrnHydroBox4 = (props) => {


    const { slider6_20, slider6_21, slider6_18, slider6_19 , Hppmetric, slider7_3, VSDPumps, PIBCV} = useSelector((store)=>store.hydroplant);
    const dispatch = useDispatch()
    
  return (
    <Box  marginTop={'13%'} sx={{display:{xs:'none', sm:'none', md:'block', lg:'block'}}}>
    <Grid container style={{ textAlign: 'center' }} >
        <Grid item md={2} lg={2} >
            <Box mt={'120px'}>
            <Box display={props.label62_3 <= .67 ? 'block' : 'none'}>
                <h6 style={{ textAlign: 'left', marginTop: '0', fontWeight: '600' }}>
                Super Low Energy(SLE)</h6></Box>

                <Box display={props.label62_3 <= .74 ? 'block' : 'none'}>
                <h6 style={{ textAlign: 'left', marginTop: '0', fontWeight: '600' }}>
                Platinum</h6></Box>

                <Box display={props.label62_3 <= .79 ? 'block' : 'none'}>
                <h6 style={{ textAlign: 'left', marginTop: '0', fontWeight: '600', color:'yellow' }}>
                EnergyPLUS</h6></Box>

            </Box>
        </Grid>
        <Grid item md={1} lg={1}>
            <CustomSlider
                SliderName={'Flow USGPM'}
                height={129}
                max={30}
                min={-20}
                value={slider6_20}
                onChange={e => dispatch(SlideSlider6_20(e.target.value))}
                unitnameDisplay={Hppmetric === true? 'block' : 'none'}
                        unitvalueDisplay={Hppmetric === true? 'block' : 'none'}
                unitname={'Flow L/S'}
                unitvalue={props.label48_54}
                BottomName={props.label105_1}
            />
        </Grid>
        <Grid item md={1} lg={1} >
            <CustomSlider
             
                SliderName={'Head mts'}
                height={129}
                min={-20}
                max={30}
                value={slider6_21}
                onChange={e => dispatch(SlideSlider6_21(e.target.value))}
                unitname={'KPA'}
                unitvalue={props.label53_1}
                BottomName={props.label48_55}


            />
        </Grid>
        <Grid item md={2} lg={2} >

            <CustomCheckBox style={{ marginTop: '140px' }} name={"Metric"} checked={Hppmetric} onChange={event => dispatch(ModelHPPMetric(!Hppmetric))} />
        </Grid>
        <Grid item md={1} lg={1} >
            <CustomSlider
                SliderName={'Flow USGPM'}
                height={129}
                min={-20}
                max={30}
                value={slider6_18}
                onChange={e => dispatch(SlideSlider6_18(e.target.value))}
                unitnameDisplay={Hppmetric === true? 'block' : 'none'}
                        unitvalueDisplay={Hppmetric === true? 'block' : 'none'}
                unitname={'Flow L/S'}
                unitvalue={props.label48_57}
                BottomName={props.label105}
            />
        </Grid>
        <Grid item xs={1} md={1} lg={1} sm={2}>
            <CustomSlider
                SliderName={'Head mts'}
                height={129}
                max={30}
                min={-10
                }
                value={slider6_19}
                onChange={e => dispatch(SlideSlider6_19(e.target.value))}
                unitname={'KPA'}
                unitvalue={props.label63_1}
                BottomName={(props.label48_58)}
            />
        </Grid>
        <Grid item md={4} lg={4} >
            <Stack direction={'row'} spacing={1} position={'relative'} marginTop={'-60px'} paddingRight={'30px'}>
                <Box height={'200px'} width={'300px'} >
                <Box display={PIBCV === true ? 'block' : 'none'}>
                <img src="../images/PIBV.png"/>
                </Box>
                  
                </Box>
                <Box textAlign={'left'}>
                    <FormControlLabel value="end" control={<CheckBoxStyled checked={PIBCV} onChange={event => dispatch(ModelPIBCV(!PIBCV))} />}
                        label={<h1 className="headingStyle">PIBCV</h1>} />

                    <FormControlLabel value="end" control={<CheckBoxStyled checked={VSDPumps} onChange={event => dispatch(ModelVSDPUMP(!VSDPumps))} />}
                        label={<h1 className="headingStyle">VSDPumps</h1>} />

                    <h1 className="headingStyle" style={{fontSize:'30px'}} >Plant COP</h1>
                    <h1 className="headingStyle" style={{fontSize:'30px'}}>{props.label99}</h1>
                    <h1 className="headingStyle" style={{fontSize:'30px'}}>{props.label100}</h1>
                </Box>
            </Stack>
            <Box>


            </Box>
        </Grid>

    </Grid>
</Box>
  )
}

export default LgSrnHydroBox4