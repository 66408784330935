
import React, { useContext } from "react";
import { Grid, Box } from "@mui/material";
import "../../../../CSS/index.css";
import CustomHorizontalSlider from "../../../../customComponent/CstmHozSlider";
import { useSelector, useDispatch } from "react-redux";
import { SlideSlider7_1 } from "../../../../Redux/Components/HydroPlant/HydroPlantSlice";

const HydroBox2 = (props) => {
    const { slider7_1, PIBCV } = useSelector((store) => store.hydroplant);
    const dispatch = useDispatch()


    const marks = [

        {
            value: 80,
            label: "50%"
        },
        {
            value: 90,
            label: "75%"
        },
        {
            value: 100,
            label: "100%"
        }
    ];
    return (
        <>
            <Box margin={'2%'}>
                <Grid container>

                    <Grid item sm={4} lg={4} md={4} xs={4}>
                        <h6>Chiller Per year</h6>
                    </Grid>

                    <Grid item sm={3} lg={3} md={3} xs={3}>
                        <h6> $ {''} {props.label44_8}</h6>
                    </Grid>


                    <Grid item sm={3} lg={3} md={3} xs={3}>
                        <Box display={PIBCV === true ? 'block' : 'none'}><h6> $ {" "}{props.label44_17}</h6></Box>
                    </Grid>


                    <Grid item sm={4} lg={4} md={4} xs={4}>
                        <h6>CHWP Opex Per Year</h6>
                    </Grid>


                    <Grid item sm={3} lg={3} md={3} xs={3}>
                        <h6>${" "}{props.label49_5}</h6>
                    </Grid>


                    <Grid item sm={3} lg={3} md={3} xs={3}>
                        <Box display={PIBCV === true ? 'block' : 'none'}><h6>$ {" "}{props.label49_9}</h6></Box>
                    </Grid>


                    <Grid item sm={4} lg={4} md={4} xs={4}>
                        <h6>CWP Opex Per Year</h6>
                    </Grid>

                    <Grid item sm={3} lg={3} md={3} xs={3}>
                        <h6>${" "} {props.label49_7}</h6>
                    </Grid>


                    <Grid item sm={3} lg={3} md={3} xs={3}>
                        <Box display={PIBCV === true ? 'block' : 'none'}><h6> ${" "}{props.label49_10}</h6></Box>
                    </Grid>


                    <Grid item sm={4} lg={4} md={4} xs={4}>
                        <h6>Fan Opex Per year</h6>
                    </Grid>

                    <Grid item sm={3} lg={3} md={3} xs={3}>
                        <h6>$ {" "}{props.label50_1}</h6>
                    </Grid>

                    <Grid item sm={3} lg={3} md={3} xs={3}>
                        <Box display={PIBCV === true ? 'block' : 'none'}><h6>${" "}{props.label50_2}</h6></Box>
                    </Grid>



                    <Grid item xs={5} sm={5} lg={5} md={5}>
                        <h6>Conventional Setup </h6>
                        <Box display={PIBCV === true ? 'block' : 'none'}><h6>DANFOSS PIBCV Setup</h6></Box>
                    </Grid>

                    <Grid item xs={5} sm={5} lg={5} md={5}>
                        <h6>${" "}{props.label86}</h6>
                        <Box display={PIBCV === true ? 'block' : 'none'}><h6> ${""}{props.label87}</h6></Box>
                    </Grid>


                </Grid>
                <Box mt={'20px'} sx={{ display: { sm: 'block', xs: 'block', md: 'none', lg: 'none' } }}>
                    <CustomHorizontalSlider display={PIBCV}
                        SliderName={'Chiller Load%'}
                        marks={marks}
                        color={'primary'}
                        min={80} max={100} value={slider7_1}
                        width={'90%'}
                        onChange={
                            e => dispatch(SlideSlider7_1(e.target.value))
                        }
                    />
                </Box>


            </Box>
        </>
    );
}

export default HydroBox2;

