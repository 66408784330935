import React from "react";
import { Grid,Box } from "@mui/material";
import "../../../../CSS/index.css";
import SmallBox2 from "../../Smallcomponents/Smallbox2";
import CustomSlider1 from "../../../../customComponent/CustomSlider1";
import CustomCheckBox from "../../../../customComponent/CustomCheckBox";
import { useDispatch, useSelector } from "react-redux";

import { ModelDegradation2 , SlideSlider2_3} from "../../../../Redux/Components/Chiller/ChillerSlice";

const Box4FirstContainer = (props) => {
  
    const { slider2_3, 
    degradation2 } = useSelector((store)=> store.chiller);
    const dispatch = useDispatch()
    const { selectedValue2} = useSelector((store) => store.selectedValue2)
    return (
        <>
        <Box margin={'3% 6%'}>
            <Grid container>
                <Grid item md={12} sm={12} lg={12}>

                <Box display={degradation2 === true ? 'block' : 'none'}>
                        <SmallBox2
                        dollar ={"$"}
                            Valuename={"New Opex With Oil Degradation"} Value={props.label38_1 ? props.label38_1 : 0} />
                    </Box>
                
                    <SmallBox2
                    dollar ={"$"}
                        Valuename={"Opex Per Annam"} Value={props.label30_3 ? props.label30_3 : 0} />


                    <SmallBox2
                    dollar ={"$"}
                        Valuename={"Total Cost of OwnerShip 10 years"} Value={props.label30_4 ? props.label30_4 : 0} />
                </Grid>
                <Grid item md={12} sm={12} xs={12} >
                    <Grid container>
                        <Grid item lg={2} sm={2} md={2}>
                            <CustomSlider1 height={180} width={68}
                                 m={'15px 0 0 0'}
                            display={degradation2}
                                min={0} max={20}
                                value={slider2_3}
                                orientation={"vertical"}
                                onChange={e => dispatch(SlideSlider2_3(e.target.value))}

                            />
                        </Grid>
                        <Grid item  lg={9} sm={9} md={9}>
                            <Box display={selectedValue2.Column3 === "Centrifugal Oil Free" ? "none" : 'block'}>
                        <CustomCheckBox name={'Add Oil Degradation'}
                        checked={degradation2}
                                    onChange={event => dispatch(ModelDegradation2(!degradation2))} />
</Box>
                                <Box display={degradation2 === true ? 'block' : 'none'}>
                                    <Grid container style={{ marginBottom: '60px' }}>
                                        <Grid item sm={2} lg={2} md={2} >
                                            <h6>{props.label26_2}  </h6>
                                        </Grid>
                                        <Grid item sm={8} lg={8} md={8}>
                                            <h6>Oil Degradation%</h6>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={2} sm={2} lg={2} md={2} >
                                            <h6>{props.label32_1} </h6>
                                        </Grid>
                                        <Grid item xs={8} sm={8} lg={8} md={8}>
                                            <h6>Years of Operation</h6>
                                        </Grid>
                                    </Grid>
                                </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </Box>
        </>

    )
}

export default Box4FirstContainer;