import React from "react";
import CustomSlider from "../../../customComponent/Customslider";
import CustomHorizontalSlider from "../../../customComponent/CstmHozSlider";
import { Box , Grid} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { SlideSlider6_10, SlideSlider6_8, SlideSlider6_11, SlideSlider6_9 } from "../../../Redux/Components/LowLift/LowLiftSlice";

const ToolBox1 = (props) => {

    const { slider6_8, slider6_9, slider6_10, slider6_11 } = useSelector((state)=> state.LowLift)
    const dispatch = useDispatch()


    
    return (
        <>

            <Box>
                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }}  padding={'1% 1% 10px 2%'} >
                    <CustomHorizontalSlider
                        SliderName={'Capacity'}
                        display={true}
                        size={'medium'}
                        width={'90%'}
                        value={slider6_8}
                        min={100}
                        max={1000}
                        onChange={e => dispatch(SlideSlider6_8(e.target.value))}
                        BottomName={"Kwr"}
                        BottomValue={props.label48_33}
                        unitname={'Ton'}
                        unitvalue={slider6_8}
                    />
                    <CustomHorizontalSlider
                        SliderName={'Chiller IPLV'}
                        display={true}
                        width={'90%'}
                        value={slider6_9}
                        min={22}
                        max={99}
                        onChange={e => dispatch(SlideSlider6_9(e.target.value))}
                        BottomName={"Kw/Kw"}
                        BottomValue={props.label61_1}
                        unitvalue={props.label44_10}
                        unitname={'Kw/Tn'}
                    />
                    <CustomHorizontalSlider
                        display={true}
                        SliderName={'$ per KW'}
                        width={'90%'}
                        value={slider6_10}
                        min={1}
                        max={30}
                        onChange={e => dispatch(SlideSlider6_10(e.target.value))}
                        unitvalue={slider6_10 / 100}
                    />
                    <CustomHorizontalSlider
                        display={true}
                        SliderName={'Run Hours Per Year'}
                        width={'90%'}
                        value={slider6_11}
                        min={1}
                        max={8760}
                        onChange={e => dispatch(SlideSlider6_11(e.target.value))}
                        unitvalue={slider6_11}
                    />

                </Box>
                <Box sx={{display:{xs:'none', sm:'none', md:'block', lg:'block'}}} position={'relative'} zIndex={2}>
                    <Grid container style={{textAlign:'center'}} >
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                            <CustomSlider
                             height={275}
                                marginTop={'100px'}
                                SliderName={"Capacity"} value={slider6_8}
                                min={100} max={1000}
                                onChange={
                                    e => dispatch(SlideSlider6_8(e.target.value))
                                }
                                unitname={'Ton'} unitvalue={slider6_8} BottomValue={props.label48_33} BottomName={"Kwr"}
                            />
                        </Grid>

                        <Grid item xs={3} sm={3} md={3} lg={3}>
                            <CustomSlider height={274}
                            marginTop={'100px'}
                                SliderName={"Chiller IPLV"} 
                                value={slider6_9} min={22} max={99}
                                onChange={e => dispatch(SlideSlider6_9(e.target.value))}
                                unitname={'Kw/Tn'} unitvalue={props.label44_10} BottomValue={props.label61_1} BottomName={"Kw/Kw"}
                            />
                        </Grid>


                        <Grid item xs={3} sm={3} md={3} lg={3}>
                            <CustomSlider
                            marginTop={'100px'}
                                SliderName={'$ per KW'}
                                height={274}
                                value={slider6_10}

                                min={1}
                                max={30}
                                onChange={e => dispatch(SlideSlider6_10(e.target.value))}
                                unitvalue={slider6_10 / 100}
                            />
                        </Grid>


                        <Grid item xs={3} sm={3} md={3} lg={3}>
                            <CustomSlider
                                SliderName={'Run Hours Per Year'}
                                width={43}
                                marginTop={'100px'}
                                height={274}
                                value={slider6_11}
                                min={1}
                                max={8760}
                                onChange={e => dispatch(SlideSlider6_11(e.target.value))}
                                unitvalue={slider6_11}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>

    )
}
export default ToolBox1;