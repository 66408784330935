import { styled , Button} from "@mui/material";
export const StyledButton =styled(Button)(({ theme})=>({
    variant:'outlined',
    borderRadius:'10px',
    outline: ' 2px solid white',
    outlineColor:'white',
    borderColor:'white',
    size:'medium',
    textAlign:'center',
    '&:hover': {
        backgroundColor: '#630606',
        borderColor: '#0062cc',
        boxShadow: 'none',
      },
}));