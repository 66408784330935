

import { Box} from '@mui/material';
import "./OFCbox3.css";
import "../../../CSS/index.css";
import {  useSelector } from 'react-redux';

const LgScrnOFCBox3 = () => {

    const{
        OilFreeMetric,
        Features,
        Components
    } = useSelector((store)=>store.OFC)
    

  return (
    
    <div width='100%' height='100%' position={'relative'}>
                    <img src="../images/power.png" alt="Plant1" className="HydroPlant6" />
                    <div className="Value5"><h6> {OilFreeMetric === true ? '12.1' : '53.8'} </h6></div>
                    <div className="Value6"><h6> {OilFreeMetric === true ? '6.7' : '44.1'} </h6></div>
                    <div className="Value7"><h6> {OilFreeMetric === true ? '34.5' : '94.1'} </h6></div>
                    <div className="Value8"><h6> {OilFreeMetric === true ? '29.4' : '84.9'} </h6></div>
                    <img src="../images/Checkvalve.png" alt="hydrolic" className="Hydrolic1"/>
                   <Box display = { Features === true ? 'block' :'none'}>                
                    <img src="../images/Features.png" alt='Features' className='Features'/>
                    </Box>
                    <Box display = { Components === true ? 'block' :'none'}>
                    <img  src="../images/Components.png" alt='Components' className='Components'/>
                    </Box>
                </div>
  )
}

export default LgScrnOFCBox3