import React, { useContext } from "react";
import { Grid, Box } from "@material-ui/core";
import CustomSlider1 from "../../../customComponent/CustomSlider1";
import SmallBox2 from "../Smallcomponents/Smallbox2";
import { Context } from "../../../App";
import "../../../CSS/index.css";
import CustomCheckBox from "../../../customComponent/CustomCheckBox";
import { textAlign } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import { SlideSlider2 , ModelDegradation, SliderHours, SliderPerkw} from "../../../Redux/Components/Chiller/ChillerSlice";



const LgScreenBox2 = (props) => {

    const {
        perKW,
        hours,
        slider2,
        degradation
    } = useSelector((store)=> store.chiller);
    const { selectedValue} = useSelector((store) => store.selectedValue1)

    const OilDEG = selectedValue.Column3
    
 
    // "Centrifugal Oil Free"
    const dispatch = useDispatch()
    return (
        <Box sx={{ margin: '6%' }}>
            <Grid container >
                <Grid item md={8} sm={8} lg={8}>
                    <Box display={degradation === true ? 'block' : 'none'}>
                        <SmallBox2
                        dollar={'$'}
                            Valuename={"New Opex With Oil Degradation"} Value={props.label38 ? props.label38 : 0} />
                    </Box>
                    <SmallBox2 
                    dollar={'$'}
                        Valuename={"Opex Per Annam"} Value={props.label30 ? props.label30 : 0} />

                    <SmallBox2
                    dollar={'$'}
                        Valuename={"Total Cost of OwnerShip 10 years"} Value={props.label30_1 ? Math.round(props.label30_1) : 0} />

                    <Grid item sm={12} md={12} lg={12}>
                        <Grid container >
                            <Grid item lg={2} sm={2} md={2}>
                                <CustomSlider1 height={180} width={68} display={degradation}
                                    m={'15px 0 0 0'}
                                    min={0} max={20}
                                    value={slider2}
                                    orientation={'vertical'}
                                    onChange={e => dispatch(SlideSlider2(e.target.value))}
                                />
                            </Grid>
                            <Grid item lg={9} sm={9} md={9}>
                                <Box display={OilDEG === "Centrifugal Oil Free" ? "none" : 'block'} > 
                                    <CustomCheckBox name={'Add Oil Degradation'} 
                                checked={degradation}
                                    onChange={event => dispatch(ModelDegradation(!degradation))} />

                                </Box>
                               

                                <Box display={degradation === true ? 'block' : 'none'}>
                                    <Grid container style={{ marginBottom: '60px' }}>
                                        <Grid item sm={2} lg={2} md={2} >
                                            <h6>{props.label26}</h6>
                                        </Grid>
                                        <Grid item sm={8} lg={8} md={8}>
                                            <h6>Oil Degradation %</h6>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={2} sm={2} lg={2} md={2} >
                                            <h6>{props.label32} </h6>
                                        
                                        </Grid>
                                        <Grid item xs={8} sm={8} lg={8} md={8}>
                                            <h6>Years of Operation</h6>
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>


                </Grid>
                <Grid item md={3} lg={1} sm={2}>
                    <CustomSlider1
                        display={true}
                        height={196}
                        orientation={'vertical'}
                        value={hours}
                        min={-1}
                        max={8760}
                        onChange={e => dispatch(SliderHours(e.target.value))}
                        BottomValue={hours}
                        BottomName={'Operating Hours Per Year'}
                    />
                </Grid>
                <Grid item md={1} lg={3} sm={2}>
                    <CustomSlider1
                        display={true}
                        height={198}
                        orientation={'vertical'}
                        min={0}
                        max={35}
                        value={perKW}
                        onChange={e => dispatch(SliderPerkw(e.target.value))}
                        BottomValue={props.label25 === 'NaN' ? '0' : props.label25}
                        BottomName={`$Per KW/hr`}
                    />
                </Grid>
            </Grid>


            <Grid />



        </Box>
    )
}

export default LgScreenBox2