
import { Box } from '@mui/material'
import React from 'react';
import "../../../CSS/index.css";

const BoxField = (props) => {
  return (
    <Box width={'99%'} height={'50%'}
  style={{
      borderRadius:'2px',
      padding:'3px 0px 10px 0px',
      marginTop:'3px',
      backgroundColor:'white'
  }}
  
  >
    <h6 style={{color:'black'}}>
          {props.degradation === false ? props.selectedvalue : null}
      </h6>
  </Box>
  )
}

export default BoxField

  