
import React, {useEffect, useState} from 'react';
import "../../CSS/Home.css";
import {  Box, Stack , Alert} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { StyledButton } from '../../customComponent/StyledButton';
import { CustomMuiCard } from '../../customComponent/CustomMuiCard';
import { Outlet, Link } from "react-router-dom";
import ButtonCompt from '../../customComponent/homeCompt';
import AppBar from '../../AppBar/AppBar';
import { useSelector, useDispatch } from 'react-redux';
import { getUserData } from '../../Redux/ApiCall/UserData';
import { getSelectedvalue } from '../../Redux/ApiCall/SelectedValueSlice';
import { getCurrency } from '../../Redux/ApiCall/SelectedItem';
import Disclaim from '../Discliam/Disclaim';
import jwt_decode from 'jwt-decode'

const HomeScreen = () => {


    const {first_name, UserData} = useSelector((store)=>store.userinfo)

    const [notAgreed, setToAgree] = useState(true);

    const [showAlert, setShowAlert] = useState(false);

    const navigate = useNavigate()





  
  const dispatch = useDispatch()

  const authToken = localStorage.getItem('authToken')
  const agreed = sessionStorage.getItem('disclaimer')

    useEffect(() => {

    dispatch(getUserData())
    dispatch(getSelectedvalue())
    dispatch(getCurrency())
    if(agreed){
        setToAgree(false)
        }
    const intervalId = setInterval(checkSessionTimeout, 1000)
    return () => clearInterval(intervalId)
    }, [],
    );

    const handleDisc = ()=>{
        setToAgree(false)
        sessionStorage.setItem('disclaimer', 'agreed')
    }



const checkSessionTimeout=()=>{
    if (authToken) {
        const decodedToken = jwt_decode(authToken);
         const expirationTime = decodedToken.exp; // expiration time in Unix timestamp format
        const currentTime = Math.floor(Date.now() / 1000);

        console.log(`Time remaining = ${expirationTime - currentTime}`);
        if (expirationTime < currentTime) {
          setShowAlert(true);
          
        } 
}
}

const handleLogout=()=>{
    setShowAlert(false)
    localStorage.removeItem("authToken");
    sessionStorage.removeItem("disclaimer")
    navigate('/login');
    window.location.reload();
    
 }

    return (
        <>
     <Disclaim notAgreed ={notAgreed}  handleDisc={handleDisc} />
            <AppBar headline={<h1 className='headingStyle'>Welcome {first_name ? first_name : 'User'} </h1>}  />
           {showAlert && 
           (
      <Alert severity="warning" onClose={handleLogout}>
        Your session has expired. Please log in again
        <button style={{color:'red'}} onClick={handleLogout} >Logout</button>
      </Alert> )}
            <Box width={'100vw'} height={'100vh'} className='main' sx={{ display:{xs:'none', sm:'none', md:'block', lg:'block'}}} >
                <Stack direction={'row'} sx={{pt:'9%', px:'1%',}} justifyContent={'space-between'} >
                <CustomMuiCard CardName='Chiller Analysis'
                CardContent="This tool is a marketing tool that gives a ROI ( Return on investment ) based on a IPLV ( Integrated part load value ) for two water chillers with selected technologies."
                
                
                image='../images/X-RayTurboTT.png'  
                button = {<Link style={{ textDecoration: 'none' }} underline="none" to='/chiller'>
                <StyledButton  sx={{px:5}}>Explore</StyledButton>
                </Link>} />


                <CustomMuiCard CardName='Hydronic Tool' image='../images/PIBV.png'

                CardContent="This tool gives a visual display of performance when using pressure independent balancing valves combined with variable speed drives."
                button = {<Link style={{ textDecoration: 'none' }} underline="none" to='/hydro'>
                <StyledButton sx={{px:5}}>Explore</StyledButton>
                </Link>}
                
                />
                <CustomMuiCard CardName='Low Lift Tool'
                
                CardContent="This tool demonstrates the high performance when combining ambient relief, high leaving water temperatures and Turbocor low lift application."
                
                image='../images/temp.png'
                button = {<Link style={{ textDecoration: 'none' }} underline="none" to='/tool'>
                <StyledButton sx={{px:5}}>Explore</StyledButton>
                </Link>} 
                />


                <CustomMuiCard CardName="Value Tool" image = '../images/bulb.png'

                CardContent="This tool will help navigate and explain some of the some of the assumption in the ROI tool. For example.. plant efficiency, IPLV etc."
                button = {<Link style={{ textDecoration: 'none' }} underline="none" to='/value'>
                <StyledButton sx={{px:5}}>Explore</StyledButton>
                </Link>}/>


                <CustomMuiCard CardName='OFC' image='../images/Checkvalve-nobg.png'

                CardContent="This tool will show the savings and feature when using an OFC (Oil-Free Check valve ) from Danfoss over current valves in the market."
                button = {<Link style={{ textDecoration: 'none' }} underline="none" to='/ofc'>
                <StyledButton sx={{px:5}}>Explore</StyledButton>
                </Link>}/>
                </Stack>
            </Box>
            <Box className="main" display={{xs:'block', sm:'block', md:'none', lg:'none'}}>

                <Stack direction={'column'} className="Content" sx={{ mt:16, px:7}} >
                <Link style={{ textDecoration: 'none' }} to="/chiller" > 
                <ButtonCompt
                    btnName={"Chiller Analysis"} 
                />
                </Link>

                <Link style={{ textDecoration: 'none' }} underline="none" to="/hydro">

                    <ButtonCompt
                        btnName={"Hydronic Tool"}
                    />
                </Link>

                <Link style={{ textDecoration: 'none' }} to="/tool">
                    <ButtonCompt
                        btnName={"Low Lift Tool"}
                    />
                </Link>

                <Link style={{ textDecoration: 'none' }} to="/value">
                    <ButtonCompt
                        btnName={"Value Tool"}
                    />
                </Link>
                <Link style={{ textDecoration: 'none' }} to="/ofc">
                    <ButtonCompt
                        btnName={"OFC"}
                    />
                </Link>
                <Outlet />


            </Stack>


</Box>
        </>
    )
}

export default HomeScreen