import React, { useState, useEffect } from "react";
import { TextField, Button } from "@material-ui/core";
import axios from "axios";
import { Box } from "@mui/material";
import "./Admin.css";
import CustomStyles from "../customComponent/DDownStyles";
import Select from "react-select";
import { ModelAdminSelectedItems, getCurrency } from "../Redux/ApiCall/SelectedItem";
import { useSelector, useDispatch } from "react-redux";



const CurrencyForm = () => {
  const { AdminselectedItems, currencydata } = useSelector((store) => store.selectedItems)

  const update_id = AdminselectedItems.id
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [Success, setsuccess] = useState("");


  const [formdata, setformData] = useState({
    Column1: "",
    Column2: "",
    Column3: "",
    PowerAppsId: "",
  });


  const Column1 = formdata.Column1
  const Column2 = formdata.Column2
  const Column3 = formdata.Column3
  const PowerAppsId = formdata.PowerAppsId



  useEffect(() => {
    dispatch((getCurrency()))
  }, []);




  const handleChange = (event) => {
    setformData({ ...formdata, [event.target.name]: event.target.value });
  };

  const handleChange2 = (newValue) => {
    dispatch(ModelAdminSelectedItems(newValue))
    setformData(newValue);
  };





  const url = process.env.REACT_APP_API_URL_DATA + `currencydata/${update_id}/`



  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }
    try {
      await axios.put(url, { Column1, Column2, Column3, PowerAppsId }, config);
      setsuccess('Succesfully updated')
      setLoading(false);
      setformData("")
    } catch (error) {
      setLoading(false);
      setError(error.request.responseText)
  
    }
    setTimeout(() => {
      setError("")
      setsuccess("")
      setformData({
        Column1: "",
        Column2: "",
        Column3: "",
        PowerAppsId: "",
      })
    }, 5000)

  }

  return (
    <>
      <Box className="Box1">
        <h1 className="headingStyle">Currency Form</h1>
        {Success && <span style={{color:'white'}}>{Success}</span>}
            {error && <span style={{color:'white'}}>{error}</span>}
        <Select
          getOptionValue={(option) => `${option}`}
          id={true}
          instanceId={true}
          isSearchable={true}
          className="selectStyle"
          styles={CustomStyles}
          options={currencydata}
          value={AdminselectedItems}
          onChange={handleChange2}
          getOptionLabel={(option) => option.Column2}
          placeholder={"Find Item"}
        />
      </Box>
      <Box className="Box2">
        <form className onSubmit={handleSubmit}>
          <TextField
            className="field"
            style={{ backgroundColor: "white" }}
            required
            name="Column1"
            id="Column1"
            variant="filled"
            label="Currency"
            value={formdata.Column1}
            onChange={handleChange}
          />
          <TextField
            required
            className="field"
            name="Column2"
            id="name"
            label="Abbrevation"
            variant="filled"
            value={formdata.Column2}
            onChange={handleChange}
          />
          <TextField
            required
            className="field"
            id="name"
            label="Rate"
            name="Column3"
            variant="filled"
            value={formdata.Column3}
            onChange={handleChange}
          />

          <TextField
            required
            className="field"
            label="Power Id"
            name="PowerAppsId"
            hiddenLabel
            variant="filled"
            value={formdata.PowerAppsId}
            onChange={handleChange}
          />
          <br />
          <Button variant="contained" color="primary" type="submit" onClick={handleSubmit}>
            Update
          </Button>
        </form>
      </Box>

    </>
  );
};

export default CurrencyForm;
