import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    slider2: 3,
    OpexperAnnum: 0,
    capacity: 0,
    chiller: 50,
    perKW: 14,
    hours: 5000,
    EffAdj: 0,
    slider3: 0,
    currency: false,
    metric: true,
    imperial: false,
    Adj: false,
    degradation: false,
    SST: 37,
    lowLift: 55,
    slider2_3: 3,
    degradation2: false,
    slider3_1: 0,
    slider1: 0,
    slider5_1: 0,
}



const ChillerSlice = createSlice({
    name: 'Chiller',
    initialState,
    reducers: {
        SlideSlider5_1:(state, action)=>{
            state.slider5_1 = action.payload
        },
        SlideSlider1:(state, action)=>{
            state.slider1 = action.payload
        },
        SlideSlider3_1:(state, action)=>{
            state.slider3_1 = action.payload
        },
        ModelDegradation2:(state, action)=>{
            state.degradation2 = action.payload
        },
        SlideSlider2_3:(state, action)=>{
            state.slider2_3 = action.payload
        },
        SliderLowLift:(state, action)=>{
            state.lowLift = action.payload
        },
        SliderSST:(state, action)=>{
            state.SST = action.payload
        },
        ModelDegradation:(state, action)=>{
            state.degradation = action.payload
        },
        ModelAdj:(state, action)=>{
            state.Adj = action.payload
        },
        Modelmetric:(state, action)=>{
            state.metric = action.payload
        },
        ModelImperial:(state, action)=>{
            state.imperial = action.payload
        },
        ModelCurrency:(state, action)=>{
            state.currency = action.payload
        },
        Slideslider3:(state, action)=>{
            state.slider3 = action.payload
        },
        SlideSlider2:(state, action)=>{
            state.slider2 = action.payload
        },
        SliderOpexPerAnnum:(state, action)=>{
            state.OpexperAnnum = action.payload
        },
        SliderCapacity:(state, action)=>{
            state.capacity = action.payload
        },
        SliderChiller:(state, action)=>{
            state.chiller = action.payload
        },
        SliderPerkw:(state, action)=>{
            state.perKW = action.payload
        },
        SliderHours:(state, action)=>{
            state.hours = action.payload
        },
        SlideEffAdj:(state,action)=>{
            state.EffAdj = action.payload
        }

    }

})
export const {SlideEffAdj, SliderHours, SliderPerkw, SliderChiller, SliderCapacity, SliderOpexPerAnnum,
     SliderHydroPerHours, SlideSlider2, SlideSlider1,SlideSlider2_3,SlideSlider3_1, Slideslider3, ModelCurrency, ModelAdj, ModelDegradation, ModelDegradation2, 
    ModelImperial, ModelInput, ModelInput2, Modelmetric, ModelInput3, SliderSST, SliderLowLift, SlideSlider5_1,} = ChillerSlice.actions
export default ChillerSlice.reducer