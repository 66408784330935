import { configureStore } from "@reduxjs/toolkit";
import HydroSliceReducer from "../Components/HydroPlant/HydroPlantSlice";
import LowLiftAppReducer from "../Components/LowLift/LowLiftSlice";
import OFCReducer from "../Components/OFC/OFCSlice";
import selectedValueReducer from "../ApiCall/SelectedValueSlice"
import selectedCurrency from "../ApiCall/SelectedItem"
import selectedValue2Reducer from "../ApiCall/SelectedValue2";
import chillerReducer from '../Components/Chiller/ChillerSlice';
import valueToolReducer from '../Components/ValueTool/ValueToolSlice'
import SelectedPerkwReducer from "../ApiCall/SelectedPerkw";
import UserDataReducer from "../ApiCall/UserData";

export const store = configureStore({
    reducer:{
        chiller:chillerReducer,
        hydroplant: HydroSliceReducer,
        LowLift: LowLiftAppReducer,
        OFC: OFCReducer,
        selectedValue1 : selectedValueReducer,
        selectedItems : selectedCurrency,
        selectedValue2 : selectedValue2Reducer,
        valueTool: valueToolReducer,
        selectedPerkw : SelectedPerkwReducer,
        userinfo :UserDataReducer
    }
})



