// eslint-disable-next-line
import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { Context } from "../../../../App";
import "../../../../CSS/index.css";
import SmallBox from "../../Smallcomponents/Smallbox";
import CustomSlider1 from "../../../../customComponent/CustomSlider1.js";
import { useDispatch, useSelector } from "react-redux";
import { SlideSlider3_1 } from "../../../../Redux/Components/Chiller/ChillerSlice";


const Box3ThirdCompt = (props) => {
    const {
        slider3_1
    } = useSelector((store)=> store.chiller);

    const dispatch = useDispatch()
    return (
        <>
            <Grid container>
                <Grid item md={1} sm={1} lg={1}>
                    <CustomSlider1
                        display={true}
                        m={'30px 0 0 0'}
                        orientation={'vertical'}
                        height={251}
                        width={99}
                        value={slider3_1}
                        min={-200}
                        max={100}
                        onChange={e => dispatch(SlideSlider3_1(e.target.value))}
                    />
                </Grid>
                <Grid item md={5} sm={5} xs={5} lg={5} style={{ marginLeft: '60px' }}>
                    <Grid container style={{ marginTop: 20 }}>
                        <Grid item md={12} sm={12} xs={12} style={{ marginTop: '20px' }}>
                            <SmallBox
                                Value={props.label17_4 ? props.label17_4 : 0}
                                Valuename={"$Per/Kwr"}
                            />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} lg={12} style={{ marginTop: '50px' }}>
                            <Grid container>
                                <Grid item xs={8} sm={8} md={10} lg={10}>
                                    <h5>$Chiller</h5>
                                </Grid>
                                <Grid item xs={4} sm={4} md={2} lg={2}>
                                    <h5>{props.label30_5 ? props.label30_5 : 0}</h5>
                                </Grid>


                            </Grid>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} style={{ marginTop: '90px' }}>
                            <SmallBox
                                Value={props.label17_5 ? props.label17_5 : 0}
                                Valuename={"$Per/Ton"}
                            />

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}
export default Box3ThirdCompt;