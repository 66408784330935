import React from 'react'
import { Box,Grid } from '@mui/material';
import ToolBox1 from './ToolsBox1/ToolBox1';
import ToolBox2 from './ToolsBox2/Toolbox2';
import ToolBox3 from './ToolsBox3/ToolBox3';
import ToolBox4 from './ToolsBox4/ToolBox4';
import "../../CSS/index.css";
import CustomSlider from '../../customComponent/Customslider';
import CustomCheckBox from '../../customComponent/CustomCheckBox';
import "../../CSS/ToolBox.css";
import { useSelector, useDispatch } from 'react-redux';
import {SlideSlider6_13, ModelLowLiftApp } from '../../Redux/Components/LowLift/LowLiftSlice';
import TopBar from '../../AppBar/AppBar';
const ToolBox = () => {

    const { slider6_8, slider6_9, slider6_10, slider6_12, slider6_13, slider6_11, LowLiftApp } = useSelector((state)=> state.LowLift);
    const dispatch = useDispatch()

    const label44_4 = slider6_8;
    const label72 = .58;
    const label77 = .58;
    const label71 = slider6_13 - 29;
    const label64_4 = slider6_12;
    const label64_5 = slider6_13;
    const label44_11 = slider6_11;
    const label44_10 = slider6_9 / 100;
    const label44_5 = slider6_10 / 100;
    const label61_1 = (12 / (label44_10 * 3.412)).toFixed(2);
    const label48_33 = Math.round(label44_4 * 3.5);
    const label90 = Math.round(label64_4 * 1.8) + 32;
    const label68 = label64_4 - 5;
    const label90_2 = Math.round(label68 * 1.8) + 32;
    const label90_1 = Math.round(label64_5 * 1.8) + 32;
    const label64_6 = Math.round(label64_5 + 5.6);
    const label90_3 = Math.round(label64_6 * 1.8) + 32;
    const label115 = slider6_12 / slider6_13 * 15;
    const label65 = slider6_12 / 100 - .11;
    const label44_12 = slider6_9 / 100 - label65 + .01;
    const label78 = label71 * 2 / 100;
    const label71_1 = Math.round(label44_4 * label72 * label44_10 * label44_5 * label44_11);
    const label71_2 = Math.round(label44_4 * (label77 + label78) * label44_12 * label44_5 * label44_11);
    const label74 = label71_1 - label71_2;
    const label69= label64_6+2;
    const label70 = label68 -1 ;
    const label79 = label69 - label70;



    return (
        <Box className='Tool-Box' paddingBottom={'8%'} >
            <TopBar headline={
                <h1 className='headingStyle'>
              Low Lift & High SST Application {
                    <CustomCheckBox
                        checked={LowLiftApp}
                        onChange={
                            e => dispatch(ModelLowLiftApp(!LowLiftApp))
                        }
                    />
            }
                </h1>
            }
            />


            <Box padding={'0 2% 0 4%'}>
                <Grid container>
                    <Grid item md={4} xs={12} lg={4} sm={12}>
                        <ToolBox1
                            label48_33={label48_33}
                            label44_10={label44_10}
                            label61_1={label61_1}
                        />
                    </Grid>



                    <Grid item md={6} xs={12} sm={12} lg={6}>
                        <ToolBox2
                            label71_1={label71_1}
                            label71_2={label71_2}
                            label74={label74}
                        />
                    </Grid>



                    <Grid item md={2} xs={2} lg={2} sm={2} >
                        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}
                            textAlign='center' position={'relative'} zIndex={2}>
                            <Box  display={LowLiftApp === true ? 'block' : 'none'} >
                            <CustomSlider
                                TypoMargin={'0 30% 0 0'}
                                marginTop={'50%'}
                                SliderName={'Low Lift'}
                                height={149}
                                orientation={'vertical'}
                                value={slider6_13} min={13} max={35}
                                onChange={e => dispatch(SlideSlider6_13(e.target.value))}
                                unitname={<img src="../images/cloud.jpeg" width='100%' alt="Condenser Water" />}
                            />
                            </Box>
                        </Box>
                    </Grid>


                    <Grid item md={12} xs={12} lg={12} >
                        <ToolBox3
                            label90={label90}
                            label90_2={label90_2}
                            label90_1={label90_1}
                            label90_3={label90_3}
                            label79={label79}
                            label64_4 ={label64_4}
                            label68= {label68}
                            label64_6 ={label64_6}
                            label64_5 = {label64_5}
                        />
                    </Grid>


                    
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ToolBox4
                            label115={label115}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default ToolBox;