import {  Card, CardActions,CardContent ,CardMedia, Stack, Typography } from "@mui/material";

import React from 'react'

export const CustomMuiCard = (props) => {
    return (
        <Card sx={{ maxWidth: 250,bgcolor:'#E10010',borderEndEndRadius:30, borderTopLeftRadius:20, py:'1%', mx:'1%',":hover":{bgcolor:'#B0000F'}, outline: '1px solid white'}} >
          <CardMedia sx={{objectFit:'contain'}}
            component="img"
            alt="Pic"
            height="200"
            image={props.image}
            
            
          />
    
          <CardContent>
            <h1 className="headingStyle" style={{textAlign:'left'}}> {props.CardName}</h1>
            <h6 style={{textAlign:'left'}}>{props.CardContent}</h6>
          </CardContent>
          <CardActions>

{props.button}
          </CardActions>
        </Card>
      );
}

