import React, { useContext } from "react";
import { Grid, Box } from "@material-ui/core";
import MidComptGird from "../../Smallcomponents/MidComptGrid";
import "../../../../CSS/index.css";
import { Context } from "../../../../App";
import { useSelector } from "react-redux";

const Box3MidContent = (props) => {
    const { selectedValue2 } = useSelector((store)=> store.selectedValue2)
    const {
        slider1,
        slider5_1,
        imperial,
        metric
    } = useSelector((store)=> store.chiller);

    const TotalChiller = Math.round(selectedValue2.Column5 + slider1);
    const TotalIPlV = selectedValue2.Column7 + (slider5_1 / 100);
    const load1 = selectedValue2.Column6;
    const ChillerTr = selectedValue2.Column5 * .28434 + (slider1 / 3.517);
    const load2 = 12 / (selectedValue2.Column6 * 3.412);
    const IPLVTR = ( 12 / (TotalIPlV * 3.412)).toFixed(2);
    return (
        <>
        <Grid container style={{marginTop:'20px'}}>
            
            <Box sx={{ height: '100%', width: '100%', textAlign: 'center', m: "2% 0"}} display={metric === true ? 'block' : 'none'} >
                <Grid container >
                    <MidComptGird
                        valuename={"Chiller 1 Kwr"}
                        value={TotalChiller ? TotalChiller : slider1}
                    />
                    <MidComptGird
                        valuename={"Full load"}
                        value={load1 ? load1 : 0}
                    />
                    <MidComptGird
                        valuename={"IPLV Kw/Kwr"}
                        value={(TotalIPlV ? TotalIPlV.toFixed(2) : slider5_1 / 100)}
                    />
                </Grid>
            </Box>
           
            <Box sx={{ textAlign: 'center', width: '100%', height: '100%', m: "3% 0%" }}  display={imperial === true ? 'block' : 'none'} >
                <Grid container>
                    <MidComptGird
                        valuename={"Chiller TR"}
                        value={Math.round(ChillerTr ? ChillerTr : slider1 / 3.517)}
                    />
                    <MidComptGird
                        valuename={"Full load"}
                        value={load2 ? load2.toFixed(2) : 0}
                    />
                    <MidComptGird
                        valuename={"IPLV Kw/Kwr"}
                        value={IPLVTR ?  IPLVTR : 0 }
                    />
                </Grid>
            </Box>
            <Grid item md={6} xs={6} sm={6} lg={6}>
                <h6>Kw/hr</h6>
                <h6>{props.label16_2 ? props.label16_2 : 0}</h6>
            </Grid>
            <Grid item md={6} xs={6} sm={6} lg={6}>
                <h6>Co2 Emissions Tons</h6>
               <h6>{props.label16_3 ? props.label16_3 : 0}</h6>
            </Grid>
            </Grid>
        </>
    )
}

export default Box3MidContent;



